import React, { useState, useContext, useEffect, useCallback } from "react";
import classes from "./Checkout.module.css";
import CartItem from "../components/Layout/Checkout/CartItem";
import UserInfoForm from "../components/Layout/Checkout/UserInfoForm";
import CartContext from "../store/cart-context";
import "../components/Layout/Checkout/CartButton.css";
import Button from "../components/UI/Button";
import ValidationHelper from "../helpers/ValidationHelper";
import FunctionsHelper from "../helpers/FunctionsHelper";
import { database } from "../firebase";
import { Urls, StateData } from "../enums/Constants";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import GoogleAnalyticsTracker from "./../helpers/GoogleAnalyticsTracker";
import FacebookTracker from "./../helpers/FacebookTracker";
import HelmetContent from "../components/Layout/HelmetContent";

const notify = (message) =>
  toast.error(message, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const couponActive =
  process.env.REACT_APP_COUPON_CODE_ACTIVE === "0" ? false : true;
const couponCode = process.env.REACT_APP_COUPON_CODE_1;
const couponPct = process.env.REACT_APP_COUPON_PCT_1;

const Checkout = (props) => {
  const cartCtx = useContext(CartContext);
  const [shippingCost, setShippingCost] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedTouched, setIsSubmittedTouched] = useState(false);
  const [isApplyTouched, setIsApplyTouched] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);
  const [isCalculatedTouched, setIsCalculatedTouched] = useState(false);
  const [formInfo, setFormInfo] = useState({
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    email: "",
    additionalNotes: "",
  });
  const [validZipCode, setValidZipCode] = useState(false);
  const [validState, setValidState] = useState(false);
  const [outOfStock, setOutOfStock] = useState([]);
  const [emptyCart, setEmptyCart] = useState(!cartCtx.items.length > 0);
  const [coupon, setCoupon] = useState("");
  const [couponPercetage, setCouponPercetage] = useState(1);
  const { t } = useTranslation();
  const frontEndUrl = process.env.REACT_APP_FRONTEND_URL;
  const environment = process.env.REACT_APP_GOOGLE_ANALYTICS_ENV + " - ";
  const gaService = GoogleAnalyticsTracker(environment + "Checkout");
  const fbService = FacebookTracker(environment + "Checkout");
  const helmetData = {
    helmetTitle: "Shopping Cart | Talvi Wear",
    helmetContent:
      "Shop with confidence, knowing that your data is always protected. You can trust Talvi Wear to handle your online payments with the utmost care and security",
    helmetUrl: frontEndUrl + "checkout",
    helmetImage: "/logo192.jpg",
    helmetZoom: false,
  };

  // const data = cartCtx.items.map((item) => ({
  //   id: `${item.selectedInventoryCode}|${item.selectedSize.toLowerCase()}`,
  //   title: item.product.name,
  //   description:
  //     item.product.name +
  //     " - " +
  //     item.selectedSize +
  //     " - " +
  //     item.selectedColor,
  //   pictureUrl: item.productImage,
  //   currencyId: "USD",
  //   quantity: item.quantity,
  //   price: item.product.price,
  // }));

  const formInfoHandler = (form) => {
    setFormInfo(form);
  };

  const validZipCodeHandler = (valid) => {
    setValidZipCode(valid);
    setShippingCost(0);
  };

  const validStateHandler = (valid) => {
    setValidState(valid);
    setShippingCost(0);
  };

  const calculatedHandler = (isCalculated) => {
    setIsCalculated(isCalculated);
  };

  const shippingHandler = () => {
    gaService.eventTracker("Calculate Shipping");
    setIsCalculatedTouched(true);
    if (validZipCode && validState) {
      setIsCalculated(true);
      calculateShipping();
    }
  };

  const validateForm = async () => {
    setLoading(true);
    // console.log(formInfo);
    const valid =
      !emptyCart &&
      ValidationHelper.validateSimpleText(formInfo.firstName) &&
      ValidationHelper.validateSimpleText(formInfo.lastName) &&
      ValidationHelper.validateSimpleText(formInfo.addressLine1) &&
      ValidationHelper.validateSimpleText(formInfo.city) &&
      ValidationHelper.validateSimpleText(formInfo.state) &&
      ValidationHelper.validateZipCode(formInfo.zipCode) &&
      ValidationHelper.validatePhoneNumber(formInfo.phoneNumber) &&
      ValidationHelper.validateEmail(formInfo.email) &&
      (await hasStock());
    setIsValid(valid);
    setLoading(false);
  };

  const validateCoupon = useCallback(async () => {
    const validCoupon = coupon === couponCode && couponActive;
    setIsValidCoupon(validCoupon);
  }, [coupon]);

  const hasStock = async () => {
    const order = cartCtx.items.map((item) => ({
      description:
        item.product.name +
        " - " +
        item.selectedSize +
        " - " +
        item.selectedColor,
      inventoryCode: item.product.colors.find(
        (color) => color.color.colorName === item.selectedColor
      ).inventoryCode,
      size: item.selectedSize.toLowerCase(),
      quantity: item.quantity,
    }));

    const inventoryRef = database.inventory;
    const snapshot = await inventoryRef.get();
    const inventory = [];
    const outOfStockArray = [];
    snapshot.forEach((doc) => {
      inventory.push({ inventoryCode: doc.id, data: doc.data() });
    });
    const outOfStockItems = order.map((item) => {
      const outOfStockItem = inventory.find((selectedItem) => {
        const check =
          selectedItem.inventoryCode === item.inventoryCode &&
          selectedItem.data[item.size] < item.quantity;
        if (check) {
          outOfStockArray.push({
            description: item.description,
            stock: selectedItem.data[item.size],
          });
        }
        return check;
      });
      return !!outOfStockItem;
    });
    setOutOfStock(outOfStockArray);
    if (outOfStockItems.length > 0) {
      const hasOutOfStockItems = outOfStockItems.reduce(
        (acum, current) => acum || current
      );
      return !hasOutOfStockItems;
    } else {
      return false;
    }
  };

  const calculateShipping = useCallback(() => {
    // console.log(validState);
    if (validZipCode && validState && (isCalculated || isSubmitted)) {
      setLoading(true);
      const shippingRate = StateData.unitedStates[formInfo.state].shippingRate;
      setShippingCost(shippingRate);
      setIsCalculated(false);
      setIsCalculatedTouched(false);
      if (isCalculated) {
        setLoading(false);
      }
    }
  }, [formInfo.state, isCalculated, isSubmitted, validZipCode, validState]);

  const triggerPayment = () => {
    gaService.eventTracker("Checkout");
    fbService.eventTracker("InitiateCheckout");
    setIsSubmitted(true);
    setIsSubmittedTouched(true);
    validateForm();
  };

  const openPaymentSession = useCallback(() => {
    if (isValid && isSubmitted && shippingCost > 0) {
      const formattedItems = cartCtx.items.map((item) => {
        return {
          id: FunctionsHelper.returnItemId(
            item.product.id,
            item.selectedSize,
            item.selectedColor
          ),
          size: item.selectedSize,
          color: item.selectedColor,
          quantity: item.quantity,
        };
      });
      // console.log(formattedItems);
      fetch(`${Urls.backEndUrl}/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          items: formattedItems,
          coupon: coupon,
          formInfo,
        }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          return res.json().then((json) => Promise.reject(json));
        })
        .then(({ url }) => {
          window.location = url;
        })
        .catch((e) => {
          console.error(e.error);
          alert("Unexpected error");
          setIsSubmitted(false);
          setLoading(false);
          setIsValid(false);
        });
      setLoading(false);
    }
  }, [cartCtx.items, formInfo, coupon, isSubmitted, isValid, shippingCost]);

  const applyCoupon = useCallback(() => {
    setIsApplyTouched(true);
    validateCoupon();
  }, [validateCoupon]);

  useEffect(() => {
    calculateShipping();
  }, [isCalculated, calculateShipping]);

  useEffect(() => {
    if (isValid && isSubmitted) {
      calculateShipping();
      openPaymentSession();
    }
  }, [isValid, isSubmitted, calculateShipping, openPaymentSession]);

  useEffect(() => {
    if (outOfStock && outOfStock.length > 0) {
      outOfStock.forEach((item) => {
        notify(
          `Insuficient stock for ${item.description} (${item.stock} available)`
        );
      });
      setOutOfStock([]);
    }
  }, [outOfStock]);

  useEffect(() => {
    setEmptyCart(!cartCtx.items.length > 0);
  }, [setEmptyCart, cartCtx.items.length]);

  useEffect(() => {
    if (isValidCoupon) {
      gaService.eventTracker("Applied Coupon");
      setCouponPercetage(couponPct);
    } else {
      setCouponPercetage(0);
    }
  }, [isValidCoupon, gaService, setCouponPercetage]);

  return (
    <div>
      <HelmetContent helmetData={helmetData} />
      <div className={classes.banner}>
        <div className={classes["banner-image"]} />
        <div className={classes["banner-inner"]}>
          <h1>{t("checkout_title")}</h1>
        </div>
        <div className={classes.spacer}></div>
      </div>
      <div className={classes["main-section-container"]}>
        <div className={classes["main-section"]}>
          <UserInfoForm
            formInfo={formInfo}
            setFormInfo={formInfoHandler}
            isSubmittedTouched={isSubmittedTouched}
            isCalculatedTouched={isCalculatedTouched}
            setValidZipCode={validZipCodeHandler}
            setValidState={validStateHandler}
            setIsCalculated={calculatedHandler}
          />
          <div className={classes["order-info"]}>
            <h2>{t("checkout_order_title")}</h2>
            <div className={classes["order-info-header"]}>
              <div>{t("checkout_order_1")}</div>
              <div>SUBTOTAL</div>
            </div>
            <div className={classes["order-info-spacer"]} />
            <div className={classes["order-info-items"]}>
              {!cartCtx.items.length > 0 && (
                <p className={classes["empty-cart"]}>
                  {t("checkout_empty_cart")}
                </p>
              )}
              {cartCtx.items.map((item) => (
                <CartItem
                  key={item.product.id + item.selectedSize + item.selectedColor}
                  item={item}
                />
              ))}
            </div>

            <div className={classes["input-coupon"]}>
              <input
                onChange={(e) => {
                  if (!isValidCoupon) {
                    setIsApplyTouched(false);
                  }

                  setCoupon(e.target.value.toUpperCase());
                }}
                value={coupon}
                name="coupon"
                type="text"
                placeholder="Enter coupon code here"
              />
              <Button onClick={applyCoupon}>APPLY</Button>
              {isApplyTouched && !isValidCoupon && (
                <p className={classes["warning-coupon"]}>Invalid code</p>
              )}
              {isApplyTouched && isValidCoupon && (
                <p className={classes["success-coupon"]}>Code applied!</p>
              )}
            </div>

            <div className={classes["order-info-spacer"]} />
            {false && (
              <div className={classes["order-info-shipping"]}>
                <input
                  value={`$${shippingCost.toFixed(2)}`}
                  name="shipping_cost"
                  disabled
                />
                <Button onClick={shippingHandler}>
                  {t("checkout_order_calc_shipping")}
                </Button>
              </div>
            )}
            <div className={classes["free-shipping"]}>
              {t("free_shipping").toUpperCase()}
            </div>
            {isCalculatedTouched && !validZipCode && !validState && (
              <p className={classes.warning}>Please enter a valid zip code</p>
            )}
            <div className={classes["order-info-spacer"]} />
            <div className={classes["order-info-subtotal"]}>
              <div>
                <strong>SUBTOTAL</strong>
              </div>
              <div>
                <strong>${cartCtx.totalAmount.toFixed(2)}</strong>
              </div>
            </div>
            <div className={classes["order-info-shipping"]}>
              <div>
                <strong>SHIPPING</strong>
              </div>
              <div>
                {/* {shippingCost !== 0 && <p>${shippingCost.toFixed(2)}</p>}
                {shippingCost === 0 && <p>-</p>} */}
                <strong style={{ color: "#198754" }}>${(0).toFixed(2)}</strong>
              </div>
            </div>
            {isApplyTouched && isValidCoupon && !emptyCart && (
              <div className={classes["order-info-discount"]}>
                <div>
                  <strong>DISCOUNT ({couponPercetage * 100}%)</strong>
                </div>
                <div style={{ textAlign: "right" }}>
                  <div>
                    <strong>
                      -${(cartCtx.totalAmount * couponPercetage).toFixed(2)}
                    </strong>
                  </div>
                </div>
              </div>
            )}
            <div className={classes["order-info-total"]}>
              <div>
                <strong>TOTAL</strong>
              </div>
              <div style={{ textAlign: "right" }}>
                <div>
                  <strong>
                    $
                    {(
                      cartCtx.totalAmount * (1 - couponPercetage) +
                      shippingCost
                    ).toFixed(2)}
                  </strong>
                </div>
                <div style={{ fontSize: "0.8em" }}>
                  <i>(Tax calculated at checkout)</i>
                </div>
              </div>
            </div>
            <Button
              className={classes["cart-checkout"]}
              onClick={triggerPayment}
            >
              {t("checkout_order_place_order")}
              {loading && <div className="loader">Loading...</div>}
            </Button>
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
