import React, { Fragment, useState, useContext } from "react";
import CartContext from "../../../store/cart-context";
import Modal from "../../UI/Modal";
import Button from "../../UI/Button";
import CartItem from "./CartItem";
import Shipment from "./Shipment";
import MercadoPagoComponent from "../../Payment/MercadoPago/MercadoPagoComponent";
import classes from "./CartModal.module.css";

const CartModal = (props) => {
  const cartCtx = useContext(CartContext);
  const [checking, setChecking] = useState(false);

  const checkoutHandler = () => {
    setChecking((prevState) => !prevState);
  };

  return (
    <Fragment>
      <Shipment />
      {!checking && (
        <Modal
          className={classes["cart-modal-container"]}
          show={props.openedCart}
          onClick={props.closeCart}
        >
          <div className={classes["cart-modal"]}>
            <button className={classes.close} onClick={props.closeCart}>
              x
            </button>
            <h1>Cart</h1>
            <div className={classes.content}>
              {!cartCtx.items.length > 0 && (
                <p className={classes["empty-cart"]}>Cart is empty</p>
              )}
              {cartCtx.items.map((item) => (
                <CartItem
                  key={item.product.id + item.selectedSize + item.selectedColor}
                  item={item}
                />
              ))}
              {cartCtx.items.length > 0 && (
                <div className={classes.totals}>
                  <h4>QTY: {cartCtx.totalQuantity}</h4>
                  <h4>TOTAL: ${cartCtx.totalAmount.toFixed(2)}</h4>
                </div>
              )}
            </div>
            <Button
              disabled={!cartCtx.items.length > 0}
              onClick={checkoutHandler}
              className={classes.checkout}
            >
              CHECKOUT
            </Button>
          </div>
        </Modal>
      )}
      {checking && (
        <MercadoPagoComponent
          show={props.openedCart}
          onClick={props.closeCart}
          goBack={checkoutHandler}
        />
      )}
    </Fragment>
  );
};

export default CartModal;
