import ReactGA from "react-ga4";

const GoogleAnalyticsTracker = (category = "Test category") => {
  const eventTracker = (action = "test action", label = "test label") => {
    ReactGA.event({ category, action, label });
  };

  const conversionTracker = (target) => {
    ReactGA.gtag("event", "conversion", { send_to: target });
  };

  const gaService = { eventTracker, conversionTracker };
  return gaService;
};

export default GoogleAnalyticsTracker;
