import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import classes from "./AuthForm.module.css";
import Button from "../../UI/Button";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../store/auth-context";
import ValidationHelper from "../../../helpers/ValidationHelper";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

const notify = (message) =>
  toast.error(message, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const AuthForm = (props) => {
  const [isLogin, setIsLogin] = useState(true);
  const { login, signup } = useAuth();
  const history = useHistory();
  const [isSubmittedTouched, setIsSubmittedTouched] = useState();
  const [isSubmitted, setIsSubmitted] = useState();
  const [isValid, setIsValid] = useState(false);
  const [formInfo, setFormInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [loginError, setLoginError] = useState("");
  const { t } = useTranslation();

  const switchAuthModelHandler = () => {
    setIsLogin((prevState) => !prevState);
  };

  const validateForm = () => {
    let valid =
      ValidationHelper.validateEmail(formInfo.email) &&
      ValidationHelper.validateSimpleText(formInfo.password);
    if (!isLogin) {
      valid =
        valid &&
        ValidationHelper.validateSimpleText(formInfo.firstName) &&
        ValidationHelper.validateSimpleText(formInfo.lastName);
    }
    setIsValid(valid);
  };

  const signUpHandler = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsSubmittedTouched(true);
    validateForm();
  };

  useEffect(() => {
    const signUpHandlerInner = async () => {
      try {
        if (isValid && isSubmitted) {
          if (isLogin) {
            const result = await login(formInfo);
            if (result.sucess) {
              history.replace("/profile");
            } else {
              setLoginError(t("auth_wrong_credentials"));
              notify(t("auth_wrong_credentials"));
              setIsSubmitted(false);
              setIsSubmittedTouched(false);
              setFormInfo({
                email: "",
                password: "",
              });
            }
          } else {
            signup(formInfo);
          }
        }
      } catch (err) {
        setLoginError(t("auth_wrong_credentials"));
      }
    };
    signUpHandlerInner();
  }, [
    isValid,
    isSubmitted,
    formInfo.email,
    formInfo.password,
    history,
    isLogin,
    login,
    signup,
    formInfo,
    t,
  ]);

  return (
    <div className={classes["auth-form-container"]}>
      <div className={classes["auth-form"]}>
        <h2>{isLogin ? t("profile_log_in") : t("profile_sign_up")}</h2>
        <form>
          {!isLogin && (
            <Fragment>
              <div className={classes.control}>
                <label>{t("profile_first_name")}</label>
                <input
                  onChange={(e) => {
                    setIsSubmitted(false);
                    setFormInfo((prevState) => ({
                      ...prevState,
                      firstName: e.target.value,
                    }));
                  }}
                  value={formInfo.firstName}
                  type="first_name"
                />
                {isSubmittedTouched &&
                  !ValidationHelper.validateSimpleText(formInfo.firstName) && (
                    <p className={classes.warning}>
                      {t("form_please_complete_field")}
                    </p>
                  )}
              </div>
              <div className={classes.control}>
                <label>{t("profile_last_name")}</label>
                <input
                  onChange={(e) => {
                    setIsSubmitted(false);
                    setFormInfo((prevState) => ({
                      ...prevState,
                      lastName: e.target.value,
                    }));
                  }}
                  value={formInfo.lastName}
                  type="last_name"
                />
                {isSubmittedTouched &&
                  !ValidationHelper.validateSimpleText(formInfo.lastName) && (
                    <p className={classes.warning}>
                      {t("form_please_complete_field")}
                    </p>
                  )}
                {loginError && <p className={classes.warning}>{loginError}</p>}
              </div>
            </Fragment>
          )}

          <div className={classes.control}>
            <label>{t("profile_email")}</label>
            <input
              onChange={(e) => {
                setIsSubmitted(false);
                setFormInfo((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }));
              }}
              value={formInfo.email}
              type="email"
            />
            {isSubmittedTouched &&
              !ValidationHelper.validateEmail(formInfo.email) && (
                <p className={classes.warning}>
                  {t("form_please_complete_field")}
                </p>
              )}
          </div>
          <div className={classes.control}>
            <label>{t("profile_password")}</label>
            <input
              onChange={(e) => {
                setIsSubmitted(false);
                setFormInfo((prevState) => ({
                  ...prevState,
                  password: e.target.value,
                }));
              }}
              value={formInfo.password}
              type="password"
            />
            {isSubmittedTouched &&
              !ValidationHelper.validateSimpleText(formInfo.password) && (
                <p className={classes.warning}>
                  {t("form_please_complete_field")}
                </p>
              )}
          </div>
          <Button type="submit" onClick={signUpHandler}>
            {isLogin ? t("profile_log_in_button") : t("profile_sign_up_button")}
          </Button>
          <Link className={classes["forgot-password"]} to="/reset_password">
            {t("profile_forgot_password")}
          </Link>
        </form>
      </div>
      {isLogin && (
        <p>
          {t("profile_switch_1")}{" "}
          <span
            className={classes["auth-mode"]}
            onClick={switchAuthModelHandler}
          >
            {t("profile_sign_up")}
          </span>
        </p>
      )}
      {!isLogin && (
        <p>
          {t("profile_switch_2")}{" "}
          <span
            className={classes["auth-mode"]}
            onClick={switchAuthModelHandler}
          >
            {t("profile_log_in")}
          </span>
        </p>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AuthForm;
