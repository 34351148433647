import React from "react";
import classes from "./Footer.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/img/talvi-logo-footer.png";
import SubscribeFooter from "./SubscribeFooter";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={classes["footer-container"]}>
      <div className={classes["footer-main"]}>
        <div className={classes["footer-col1"]}>
          <Link to="/">
            <img className={classes.logo} src={logo} alt="Talvi Logo" />
          </Link>
          <p>{t("footer_1")}</p>
        </div>
        <div className={classes["footer-col2"]}>
          <h4>{t("footer_site_map")}</h4>
          <div className={classes.links}>
            <Link to="/home">{t("menu_home")}</Link>
          </div>
          <div className={classes.links}>
            <Link to="/blog">{t("menu_blog")}</Link>
          </div>
          <div className={classes.links}>
            <Link to="/products">{t("menu_products")}</Link>
          </div>
          <div className={classes.links}>
            <Link to="/about">{t("menu_about")}</Link>
          </div>
          <div className={classes.links}>
            <Link to="/contact">{t("menu_contact")}</Link>
          </div>
          <div className={classes.links}>
            <Link to="/policy">{t("footer_privacy_policy")}</Link>
          </div>
        </div>
        <div className={classes["footer-col3"]}>
          <h4>NEWSLETTER</h4>
          <p>{t("footer_2")}</p>
          <SubscribeFooter />
        </div>
      </div>
      <p className={classes.copyright}>{t("footer_3")}</p>
    </div>
  );
};

export default Footer;
