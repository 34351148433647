import React, { Fragment } from "react";
import classes from "./Modal.module.css";
import Backdrop from "../UI/Backdrop";

const Modal = (props) => {
  const modalClasses = `${classes["modal"]} ${props.className}`;
  const modalTransitionClass = `${props.transitionClassName}`;

  return (
    <Fragment>
      <div className={modalTransitionClass}>
        <Backdrop show={props.show} onClick={props.onClick} />
        <div className={modalClasses}>{props.children}</div>
      </div>
    </Fragment>
  );
};

export default Modal;
