import React, { Fragment, useState, useContext, useEffect } from "react";
import Button from "../../UI/Button";
import Size from "./Size";
import Color from "./Color";
import Rating from "./Rating";
import Detail from "./Detail";
import ProductCarousel from "./ProductCarousel";
import CartContext from "../../../store/cart-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { database } from "../../../firebase";
import { useTranslation } from "react-i18next";
import FunctionsHelper from "../../../helpers/FunctionsHelper";
import classes from "./Product.module.css";
import GoogleAnalyticsTracker from "./../../../helpers/GoogleAnalyticsTracker";

const getImage = (product, colorName) => {
  const color = product.colors.filter((x) => x.color.colorName === colorName);
  if (color.length > 0) {
    const images = [];
    if (color[0].imgUrls) {
      color[0].imgUrls.forEach((url) => {
        images.push(require.context("../../../assets/img", true)(`./${url}`));
      });
    }
    return images;
  }
  return null;
};

const notify = (message) =>
  toast.success(message, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const Product = (props) => {
  const [selectedInventoryCode, setSelectedInventoryCode] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(
    props.item.colors[0].color.colorName
  );
  const [productImages, setProductImages] = useState(null);
  const [addToCartTouched, setAddToCartTouched] = useState(false);
  const [loadingStock, setLoadingStock] = useState(false);
  const [stock, setStock] = useState(null);
  const [error, setError] = useState("");
  const cartCtx = useContext(CartContext);
  const customClasses = props.className + " " + classes["product-container"];
  const { t } = useTranslation();
  const environment = process.env.REACT_APP_GOOGLE_ANALYTICS_ENV + " - ";
  const gaService = GoogleAnalyticsTracker(environment + "Product");
  const rebate = 40;

  const product = props.item;

  const selectedProduct = {
    product,
    selectedInventoryCode,
    selectedSize,
    selectedColor,
    productImages,
  };

  useEffect(() => {
    const product = props.item;
    setProductImages(getImage(product, product.colors[0].color.colorName));
  }, [props.item]);

  const addItemHandler = (selectedProduct) => {
    setAddToCartTouched(true);
    if (
      selectedSize &&
      selectedColor &&
      selectedInventoryCode &&
      stock &&
      stock > 0
    ) {
      const cartItem = cartCtx.items.find(
        (item) =>
          item.selectedInventoryCode ===
            selectedProduct.selectedInventoryCode &&
          item.selectedSize === selectedProduct.selectedSize
      );
      if (!cartItem || cartItem.quantity < stock) {
        cartCtx.addItem(selectedProduct);
        setAddToCartTouched(false);
        notify(t("product_info_2"));
      } else {
        setError(t("product_info_6"));
      }
    } else if (!selectedColor) {
      setError(t("product_info_3"));
    } else if (!selectedSize) {
      setError(t("product_info_4"));
    } else if (!selectedColor && !selectedSize) {
      setError(t("product_info_5"));
    }
  };

  const sizeSelectedHandler = (size) => {
    gaService.eventTracker(
      `Select Size - ${size} from ${selectedProduct.product.name}`
    );
    setSelectedSize(size);
  };

  const colorSelectedHandler = (color) => {
    gaService.eventTracker(
      `Select Color - ${color} from ${selectedProduct.product.name}`
    );
    setSelectedColor(color);
    setProductImages(getImage(selectedProduct.product, color));
  };

  useEffect(() => {
    const getInventoryCode = async () => {
      if (selectedSize && selectedColor) {
        setLoadingStock(true);
        const inventoryCode = selectedProduct.product.colors.find(
          (color) => color.color.colorName === selectedColor
        ).inventoryCode;
        setSelectedInventoryCode(inventoryCode);

        const inventoryRef = database.inventory.doc(inventoryCode);
        const doc = await inventoryRef.get();
        if (!doc.exists) {
          // console.log("No such document!");
        } else {
          const stockData = doc.data();
          setStock(stockData[selectedSize.toLowerCase()]);
        }
        setLoadingStock(false);
      }
    };
    getInventoryCode();
  }, [selectedSize, selectedColor, selectedProduct.product.colors]);

  useEffect(() => {
    if (selectedSize && selectedColor && stock !== null && addToCartTouched) {
      if (stock > 0) {
        setError("");
      } else {
        setError(t("product_info_6"));
      }
    }
  }, [selectedSize, selectedColor, loadingStock, stock, addToCartTouched, t]);

  return (
    <Fragment>
      <div className={customClasses}>
        <div className={classes["main-product-section"]}>
          <h2
            className={`${classes["small-realstate-item"]} ${classes["mobile-title"]}`}
          >
            {selectedProduct.product.name.toUpperCase()}
          </h2>
          <ProductCarousel
            images={selectedProduct.productImages}
            productId={selectedProduct.product.id}
            productName={selectedProduct.product.name}
          />
        </div>
        <div className={classes["product-specs"]}>
          <h2 className={classes["large-realstate-item"]}>
            {selectedProduct.product.name.toUpperCase()}
          </h2>
          <div className={classes["spacer large-realstate-item"]}></div>
          <div className={classes.subtitle}>
            <h3>
              {/* {t("product_price")}:{" "} */}
              <span className={classes["product-price"]}>
                {FunctionsHelper.formatCurrency(selectedProduct.product.price)}
              </span>
              <span
                style={{
                  marginLeft: "6px",
                  fontSize: "0.8em",
                  color: "grey",
                  textDecoration: "line-through",
                }}
              >
                {FunctionsHelper.formatCurrency(
                  selectedProduct.product.price + rebate
                )}
              </span>
              <span className={classes["save-x"]}>
                {t("save_x")} {FunctionsHelper.formatCurrency(rebate)} (
                {Math.round(
                  (rebate / (selectedProduct.product.price + rebate)) * 100
                )}
                %)
              </span>
            </h3>
            {false && <Rating />}
          </div>
          <div className={classes.spacer}></div>
          <h4>{t("product_color")}</h4>
          <Color
            onClick={colorSelectedHandler}
            colors={selectedProduct.product.colors}
            selectedColor={selectedColor}
          />
          <div className={classes.spacer}></div>
          <h4>{t("product_size")}</h4>
          <Size
            onClick={sizeSelectedHandler}
            sizes={selectedProduct.product.sizes}
            selectedSize={selectedSize}
          />
          <div className={classes["stock-container"]}>
            <p className={classes["stock"]}>
              {selectedSize &&
                selectedColor &&
                !isNaN(stock) &&
                stock !== null && (
                  <Fragment>
                    {stock > 0 ? (
                      <span
                        style={{
                          color: "green",
                          fontWeight: "bold",
                        }}
                      >
                        {t("product_available_stock_in_stock")}
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {t("product_available_stock_out_of_stock")}
                      </span>
                    )}
                  </Fragment>
                )}
              {(!selectedSize || !selectedColor) && t("product_info_1")}
            </p>
            {loadingStock && (
              <div
                style={{
                  position: "absolute",
                  left: "155px",
                  top: "5px",
                  transform: "scale(0.6)",
                }}
                className="loader loader-positioned"
              >
                Loading...
              </div>
            )}
          </div>
          <div className={classes.spacer}></div>
          <Button
            className={classes["add-to-cart"]}
            disabled={(!stock || stock === 0) && addToCartTouched}
            onClick={() => {
              gaService.eventTracker(
                `Add to Cart - ${selectedProduct.product.name.toUpperCase()}`
              );
              window.ttq.track("AddToCart", {
                content_id: selectedProduct.product.id,
                content_type: "product",
                content_name: selectedProduct.product.name.toUpperCase(),
                quantity: 1,
                price: selectedProduct.product.price,
                value: selectedProduct.product.price,
                currency: "USD",
              });
              addItemHandler(selectedProduct);
            }}
          >
            {t("product_add_to_cart")}
          </Button>
          {error !== "" && <p className={classes["cart-warning"]}>{error}</p>}
          <div className={classes["free-shipping"]}>
            {t("free_shipping").toUpperCase()}
          </div>
          <div className={classes.spacer}></div>
          <h4>{t("product_details")}</h4>
          {selectedProduct.product.details.map((detail) => (
            <Detail key={detail.title} info={detail} />
          ))}
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Product;
