import React, { useEffect } from "react";
import classes from "./UserInfoForm.module.css";
import { States } from "../../../enums/Constants";
import { useTranslation } from "react-i18next";
import ValidationHelper from "../../../helpers/ValidationHelper";

const UserInfoForm = (props) => {
  const formInfo = props.formInfo;
  const isSubmittedTouched = props.isSubmittedTouched;
  const isCalculatedTouched = props.isCalculatedTouched;
  const { t } = useTranslation();

  useEffect(() => {
    if (formInfo.state === "") {
      props.setFormInfo((prevState) => ({
        ...prevState,
        state: undefined,
      }));
    }
  });

  return (
    <div className={classes["user-info"]}>
      <div className={classes["middle-title"]}>
        <h2>{t("checkout_shipping_info_title")}</h2>
        <p>{t("checkout_shipping_info_1")}</p>
      </div>
      <form>
        <div className={classes["input"]}>
          <label htmlFor="firstname">{t("checkout_user_info_1")}</label>
          <input
            onChange={(e) => {
              props.setFormInfo((prevState) => ({
                ...prevState,
                firstName: e.target.value,
              }));
            }}
            value={formInfo.firstName}
            name="firstname"
            type="text"
          />
          {isSubmittedTouched &&
            !ValidationHelper.validateSimpleText(formInfo.firstName) && (
              <p className={classes.warning}>
                {t("form_please_complete_field")}
              </p>
            )}
        </div>
        <div className={classes["input"]}>
          <label htmlFor="lastname">{t("checkout_user_info_2")}</label>
          <input
            onChange={(e) => {
              props.setFormInfo((prevState) => ({
                ...prevState,
                lastName: e.target.value,
              }));
            }}
            value={formInfo.lastName}
            name="lastname"
            type="text"
          />
          {isSubmittedTouched &&
            !ValidationHelper.validateSimpleText(formInfo.lastName) && (
              <p className={classes.warning}>
                {t("form_please_complete_field")}
              </p>
            )}
        </div>
        <div className={classes["input"]}>
          <label htmlFor="phone">{t("checkout_user_info_9")}</label>
          <input
            onChange={(e) => {
              props.setFormInfo((prevState) => ({
                ...prevState,
                phoneNumber: e.target.value,
              }));
            }}
            value={formInfo.phoneNumber}
            name="phone"
            type="text"
          />
          {isSubmittedTouched &&
            !ValidationHelper.validatePhoneNumber(formInfo.phoneNumber) && (
              <p className={classes.warning}>
                {t("form_please_complete_field")}
              </p>
            )}
        </div>
        <div className={classes["input"]}>
          <label htmlFor="email">{t("checkout_user_info_10")}</label>
          <input
            onChange={(e) => {
              props.setFormInfo((prevState) => ({
                ...prevState,
                email: e.target.value,
              }));
            }}
            value={formInfo.email}
            name="email"
            type="text"
          />
          {isSubmittedTouched &&
            !ValidationHelper.validateEmail(formInfo.email) && (
              <p className={classes.warning}>
                {t("form_please_complete_field")}
              </p>
            )}
        </div>
        <div className={classes["input"]}>
          <label htmlFor="address_line_1">{t("checkout_user_info_4")}</label>
          <input
            onChange={(e) => {
              props.setFormInfo((prevState) => ({
                ...prevState,
                addressLine1: e.target.value,
              }));
            }}
            value={formInfo.addressLine1}
            name="addressLine1"
            type="text"
          />
          {isSubmittedTouched &&
            !ValidationHelper.validateSimpleText(formInfo.addressLine1) && (
              <p className={classes.warning}>
                {t("form_please_complete_field")}
              </p>
            )}
        </div>
        <div className={classes["input"]}>
          <label htmlFor="address_line_2">{t("checkout_user_info_5")}</label>
          <input
            onChange={(e) => {
              props.setFormInfo((prevState) => ({
                ...prevState,
                addressLine2: e.target.value,
              }));
            }}
            value={formInfo.addressLine2}
            name="addressLine2"
            type="text"
          />
        </div>
        <div className={classes["input"]}>
          <label htmlFor="zip_code">{t("checkout_user_info_8")}</label>
          <input
            onChange={(e) => {
              if (e.target.value.length <= 5) {
                props.setFormInfo((prevState) => ({
                  ...prevState,
                  zipCode: e.target.value,
                }));
              }
              props.setIsCalculated(false);
              props.setValidZipCode(
                ValidationHelper.validateZipCode(e.target.value)
              );
            }}
            value={formInfo.zipCode}
            name="zip_code"
            type="text"
          />
          {(isSubmittedTouched || isCalculatedTouched) &&
            !ValidationHelper.validateZipCode(formInfo.zipCode) && (
              <p className={classes.warning}>
                {t("form_please_complete_field")}
              </p>
            )}
        </div>
        <div className={classes["input"]}>
          <label htmlFor="city">{t("checkout_user_info_6")}</label>
          <input
            onChange={(e) => {
              props.setFormInfo((prevState) => ({
                ...prevState,
                city: e.target.value,
              }));
            }}
            value={formInfo.city}
            name="city"
            type="text"
          />
          {isSubmittedTouched &&
            !ValidationHelper.validateSimpleText(formInfo.city) && (
              <p className={classes.warning}>
                {t("form_please_complete_field")}
              </p>
            )}
        </div>
        <div className={classes["input"]}>
          <label htmlFor="state">{t("checkout_user_info_7")}</label>
          <select
            onChange={(e) => {
              if (e.target.value.length <= 4) {
                props.setFormInfo((prevState) => ({
                  ...prevState,
                  state: e.target.value,
                }));
              }
              props.setIsCalculated(false);
              props.setValidState(
                e.target.value !== null && e.target.value !== ""
              );
            }}
            value={formInfo.state}
            className={classes.dropdown}
          >
            {States.unitedStates.states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
          {(isSubmittedTouched || isCalculatedTouched) &&
            !ValidationHelper.validateSimpleText(formInfo.state) && (
              <p className={classes.warning}>
                {t("form_please_complete_field")}
              </p>
            )}
        </div>
        <div className={classes["input"]}>
          <label htmlFor="notes">{t("checkout_user_info_11")}</label>
          <textarea
            onChange={(e) => {
              props.setFormInfo((prevState) => ({
                ...prevState,
                additionalNotes: e.target.value,
              }));
            }}
            value={formInfo.additionalNotes}
            placeholder={t("checkout_user_info_12")}
            name="notes"
            type="text"
          />
        </div>
      </form>
    </div>
  );
};

export default UserInfoForm;
