import React, { useState, useEffect, useCallback, Fragment } from "react";
import classes from "./SubscribeFooter.module.css";
import Button from "../UI/Button";
import ValidationHelper from "../../helpers/ValidationHelper";
import { Urls } from "../../enums/Constants";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleAnalyticsTracker from "./../../helpers/GoogleAnalyticsTracker";
import FacebookTracker from "./../../helpers/FacebookTracker";

const SubscribeModal = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedTouched, setIsSubmittedTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [antiBotsTries, setAntiBotsTries] = useState(0);
  const subscribeModalType = process.env.REACT_APP_SUBSCRIBE_MODAL_TYPE;
  const antiBotsMax = 4;
  const antiBotsCookie = "a_b_192";
  const antiBotsTimeOut = 7200000;
  const environment = process.env.REACT_APP_GOOGLE_ANALYTICS_ENV + " - ";
  const gaService = GoogleAnalyticsTracker(environment + "Footer");
  const fbService = FacebookTracker(environment + "Footer");

  const notify = (message) =>
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const triggerSubscribe = () => {
    setIsSubmitted(true);
    setIsSubmittedTouched(true);
    validateForm();
  };

  const validateForm = async () => {
    setLoading(true);
    const valid = ValidationHelper.validateEmail(email);
    setIsValid(valid);
  };

  const sendMessage = useCallback(() => {
    if (
      isValid &&
      isSubmitted &&
      isSubmittedTouched &&
      antiBotsTries <= antiBotsMax
    ) {
      fetch(`${Urls.backEndUrl}/send_subscriber_message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: { email, subscribeModalType },
        }),
      })
        .then((res) => res.json())
        .then((message) => {
          gaService.eventTracker("Subscribed");
          gaService.conversionTracker("AW-10869692725/w8PcCMKpl5QYELWyib8o");
          fbService.eventTracker("CompleteRegistration");

          notify("Thank you for subscribing!!");
        })
        .catch((error) => {
          alert("Unexpected error: " + error);
          setLoading(false);
        })
        .finally(() => {
          setIsSubmitted(false);
          setIsSubmittedTouched(false);
          setLoading(false);
          setEmail("");
        });
    }
  }, [
    email,
    isSubmitted,
    isValid,
    isSubmittedTouched,
    antiBotsTries,
    gaService,
    fbService,
    subscribeModalType,
  ]);

  useEffect(() => {
    if (isValid && isSubmitted && antiBotsTries <= antiBotsMax && loading) {
      sendMessage();
      setIsValid(false);
      setAntiBotsTries((prevState) => prevState + 1);
    }
    if (antiBotsTries > antiBotsMax && isSubmitted) {
      alert("You reached the max number of messages!!");
    }
  }, [isValid, isSubmitted, antiBotsTries, loading, sendMessage]);

  useEffect(() => {
    const antiBots = localStorage.getItem(antiBotsCookie);
    if (antiBots) {
      const timeOut = JSON.parse(antiBots);
      const yourDateMilliseconds = timeOut.t1;
      const actualTimeMilliseconds = new Date().getTime();
      if (actualTimeMilliseconds - yourDateMilliseconds < antiBotsTimeOut) {
        setAntiBotsTries(antiBotsMax + 1);
      }
    }
  }, []);

  useEffect(() => {
    if (antiBotsTries >= antiBotsMax) {
      localStorage.setItem(
        antiBotsCookie,
        JSON.stringify({ t1: new Date().getTime() })
      );
    }
  }, [antiBotsTries]);

  return (
    <Fragment>
      <div>
        <div className={classes.content}>
          <div className={classes["input"]}>
            <input
              onChange={(e) => {
                setEmail(e.target.value);
                setIsSubmittedTouched(false);
                setLoading(false);
              }}
              value={email}
              name="email"
              type="text"
              placeholder="Email"
              disabled={antiBotsTries > antiBotsMax}
            />
          </div>
          <Button
            disabled={false}
            onClick={triggerSubscribe}
            className={classes.subscribe}
          >
            SUBSCRIBE
            {loading && <div className="loader">Loading...</div>}
          </Button>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        {isSubmittedTouched && !ValidationHelper.validateEmail(email) && (
          <p className={classes.warning}>{t("form_please_complete_field")}</p>
        )}
      </div>
    </Fragment>
  );
};

export default SubscribeModal;
