import React from "react";
import classes from "./Button.module.css";

const Button = (props) => {
  const additionalClasses = props.className;
  return (
    <button {...props} className={`${classes.button} ${additionalClasses}`}>
      {props.children}
    </button>
  );
};

export default Button;
