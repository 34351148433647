import React, { useState, useEffect } from "react";
import Button from "../../UI/Button";
import { useTranslation } from "react-i18next";
import ValidationHelper from "../../../helpers/ValidationHelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "./MyAccount.module.css";

const notifyInfo = (message) =>
  toast.info(message, {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const notifyError = (message) =>
  toast.error(message, {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const MyAccount = (props) => {
  const userInfo = props.userInfo;
  const [isSubmittedTouched, setIsSubmittedTouched] = useState();
  const [isSubmitted, setIsSubmitted] = useState();
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation();

  const validateForm = () => {
    const valid =
      ValidationHelper.validateSimpleText(userInfo.firstName) &&
      ValidationHelper.validateSimpleText(userInfo.lastName) &&
      ValidationHelper.validateSimpleText(userInfo.email) &&
      ValidationHelper.validateSimpleText(userInfo.password);
    setIsValid(valid);
  };

  const changeUserInfoHandler = () => {
    setIsSubmittedTouched(true);
    setIsSubmitted(true);
    validateForm();
  };

  useEffect(() => {
    const updateUserInner = async () => {
      try {
        if (isValid && isSubmitted) {
          const result = await props.updateUser(userInfo);
          if (result.success) {
            notifyInfo(t("profile_change_user_notify_success"));
          } else {
            notifyError(t("profile_change_user_notify_error"));
          }
          props.setUserInfo((prevState) => {
            return { ...prevState, password: "" };
          });
          setIsSubmittedTouched(false);
          return result;
        }
      } catch (error) {
        return error;
      }
    };
    updateUserInner();
    setIsValid(false);
  }, [isValid, isSubmitted, userInfo, props, t]);

  return (
    <div className={classes["main-section-container"]}>
      <div className={classes["main-section"]}>
        <div className={classes["title-loader"]}>
          <h2>{t("profile_my_account")}</h2>
          {props.loadingUserData && (
            <div className="loader loader-positioned">Loading...</div>
          )}
        </div>

        <form onSubmit={(e) => e.preventDefault()}>
          <div className={classes["same-row"]}>
            <div className={classes["middle-column"]}>
              <label htmlFor="firstname">{t("profile_my_account_1")}</label>
              <input
                onChange={(e) => {
                  setIsSubmitted(false);
                  props.setUserInfo((prevState) => ({
                    ...prevState,
                    firstName: e.target.value,
                  }));
                }}
                value={userInfo.firstName}
                name="firstname"
                type="text"
              />
              {isSubmittedTouched &&
                !ValidationHelper.validateSimpleText(userInfo.firstName) && (
                  <p className={classes.warning}>
                    {t("form_please_complete_field")}
                  </p>
                )}
            </div>
            <div className={classes["input"]}>
              <label htmlFor="lastname">{t("profile_my_account_2")}</label>
              <input
                onChange={(e) => {
                  setIsSubmitted(false);
                  props.setUserInfo((prevState) => ({
                    ...prevState,
                    lastName: e.target.value,
                  }));
                }}
                value={userInfo.lastName}
                name="lastname"
                type="text"
              />
              {isSubmittedTouched &&
                !ValidationHelper.validateSimpleText(userInfo.lastName) && (
                  <p className={classes.warning}>
                    {t("form_please_complete_field")}
                  </p>
                )}
            </div>
          </div>
          <div className={classes["input"]}>
            <label htmlFor="email">{t("profile_my_account_3")}</label>
            <input
              onChange={(e) => {
                setIsSubmitted(false);
                props.setUserInfo((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }));
              }}
              value={userInfo.email}
              name="email"
              type="text"
            />
            {isSubmittedTouched &&
              !ValidationHelper.validateEmail(userInfo.email) && (
                <p className={classes.warning}>
                  {t("form_please_complete_field")}
                </p>
              )}
          </div>
          <div className={classes["input"]}>
            <label>{t("profile_password")}</label>
            <input
              onChange={(e) => {
                setIsSubmitted(false);
                props.setUserInfo((prevState) => ({
                  ...prevState,
                  password: e.target.value,
                }));
              }}
              value={userInfo.password}
              type="password"
            />
            {isSubmittedTouched &&
              !ValidationHelper.validateSimpleText(userInfo.password) && (
                <p className={classes.warning}>
                  {t("form_please_complete_field")}
                </p>
              )}
          </div>
          <Button onClick={changeUserInfoHandler}>
            {t("profile_my_account_update")}
          </Button>
        </form>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </div>
  );
};

export default MyAccount;
