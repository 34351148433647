import React, { Fragment } from "react";
import Menu from "./components/Layout/Menu";
import Content from "./components/Layout/Content";
import Footer from "./components/Layout/Footer";
import SubscribeModal from "./components/Layout/SubscribeModal";
import UnderMaintenance from "./pages/UnderMaintenance";
import "./components/UI/Spinner.css";
import "./components/UI/CustomCarousel.css";
import "./components/Layout/Checkout/IFrameStyles.css";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

const GA_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_KEY; // OUR_TRACKING_ID
ReactGA.initialize(GA_TRACKING_ID);

const FB_TRACKING_ID = process.env.REACT_APP_FACEBOOK_TRACKING_KEY;
ReactPixel.init(FB_TRACKING_ID);

const underMaintenance =
  process.env.REACT_APP_UNDER_MAINTENANCE === "0" ? false : true;

const App = () => {
  return (
    <Fragment>
      {underMaintenance && <UnderMaintenance />}
      {!underMaintenance && (
        <Fragment>
          <SubscribeModal />
          <Menu />
          <Content
            reactGoogleAnalytics={(pathNameVar, searchVar) =>
              ReactGA.send({
                hitType: "pageview",
                page: pathNameVar + searchVar,
                title: "Custom Title",
              })
            }
            reactFacebook={(pathNameVar, searchVar) => ReactPixel.pageView()}
          />
          <Footer />
        </Fragment>
      )}
    </Fragment>
  );
};

export default App;
