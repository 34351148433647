import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import classes from "./ProductCarousel.module.css";
import GoogleAnalyticsTracker from "./../../../helpers/GoogleAnalyticsTracker";

const ProductCarousel = (props) => {
  const [images, setImages] = useState(null);
  const [swipe, setSwipe] = useState(false);
  const [swipeTouched, setSwipeTouched] = useState(false);
  const environment = process.env.REACT_APP_GOOGLE_ANALYTICS_ENV + " - ";
  const gaService = GoogleAnalyticsTracker(environment + "Product Carousel");

  useEffect(() => {
    setImages(props.images);
  }, [setImages, props.images]);

  useEffect(() => {
    if (swipe && !swipeTouched) {
      gaService.eventTracker(`Carousel swiped from ${props.productName}`);
      setSwipeTouched(true);
    }
  }, [swipe, swipeTouched, props.productName, gaService]);

  return (
    <div className={classes["product-carousel"]}>
      <Carousel
        autoPlay={false}
        showIndicators={false}
        transitionTime={500}
        showStatus={false}
        infiniteLoop={false}
        interval={9999999}
        swipeScrollTolerance={100}
        onSwipeStart={() => {
          setSwipe(true);
        }}
        onClickThumb={(i, item) => {
          gaService.eventTracker(
            `Carousel thumb for ${props.productName} clicked: Index N${
              i + 1
            } | Image: ${item.props.src}`
          );
        }}
      >
        {images &&
          images.map((image, i) => (
            <div key={i}>
              <img src={image} alt={props.productName} />
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default ProductCarousel;
