import React, { useState, useEffect, useCallback, Fragment } from "react";
import classes from "./SubscribeModal.module.css";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import SubscribeModalTemplate from "./SubscribeModalTemplate";
import ValidationHelper from "../../helpers/ValidationHelper";
import { Urls } from "../../enums/Constants";
import { useTranslation } from "react-i18next";
import GoogleAnalyticsTracker from "./../../helpers/GoogleAnalyticsTracker";
import FacebookTracker from "./../../helpers/FacebookTracker";

const SubscribeModal = () => {
  const { t } = useTranslation();
  const [openedSubscribe, setOpenedSubscribe] = useState(false);
  const [openedSubscribeTransition, setOpenedSubscribeTransition] =
    useState(false);
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedTouched, setIsSubmittedTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [loading, setLoading] = useState(false);
  const subscriptionCookie = "s_192";
  const subsDelay = 86400000;
  const subsDelayMultiplier = 3;
  const environment = process.env.REACT_APP_GOOGLE_ANALYTICS_ENV + " - ";
  const subscribeModalEnabled =
    process.env.REACT_APP_SUBSCRIBE_MODAL_ENABLED === "0" ? false : true;
  const subscribeModalType = process.env.REACT_APP_SUBSCRIBE_MODAL_TYPE;
  const gaService = GoogleAnalyticsTracker(environment + "Modal");
  const fbService = FacebookTracker(environment + "Modal");

  const retrieveSubscribe = () => {
    const subs = localStorage.getItem(subscriptionCookie);
    if (subs) {
      return JSON.parse(subs);
    }
    return null;
  };

  const persistInLocalStorage = (subs) => {
    localStorage.setItem(subscriptionCookie, JSON.stringify(subs));
  };

  const closeSubscribe = () => {
    if (openedSubscribe) {
      setTimeout(() => {
        setOpenedSubscribeTransition(false);
      }, 250);
      setOpenedSubscribe(false);
      persistInLocalStorage({ t1: new Date().getTime(), a: false });
    }
  };

  const triggerSubscribe = () => {
    setIsSubmittedTouched(true);
    setIsSubmitted(true);
    validateForm();
  };

  const validateForm = async () => {
    setLoading(true);
    const valid = ValidationHelper.validateEmail(email);
    setIsValid(valid);
  };

  const sendMessage = useCallback(() => {
    if (isValid && isSubmitted && isSubmittedTouched && !subscribed) {
      fetch(`${Urls.backEndUrl}/send_subscriber_message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: { email, subscribeModalType },
        }),
      })
        .then((res) => res.json())
        .then((message) => {
          gaService.eventTracker("Subscribed");
          fbService.eventTracker("CompleteRegistration");
          setSubscribed(true);
        })
        .catch((error) => {
          alert("Unexpected error: " + error);
          setLoading(false);
        })
        .finally(() => {
          setIsSubmitted(false);
          setIsSubmittedTouched(false);
          setLoading(false);
        });
    }
  }, [
    email,
    isSubmitted,
    isValid,
    isSubmittedTouched,
    gaService,
    fbService,
    subscribed,
    subscribeModalType,
  ]);

  const handleOpenedSubscribe = useCallback((status) => {
    setTimeout(() => {
      setOpenedSubscribeTransition(true);
    }, 2700);
    setTimeout(() => {
      setOpenedSubscribe((prevState) => {
        return status ? status : !prevState;
      });
      persistInLocalStorage({ t1: new Date().getTime(), a: true });
    }, 3000);
  }, []);

  const showSubscribe = useCallback(() => {
    const subsCookie = retrieveSubscribe();
    if (subsCookie) {
      const yourDateMilliseconds = subsCookie.t1;
      const actualTimeMilliseconds = new Date().getTime();
      if (
        actualTimeMilliseconds - yourDateMilliseconds >
          subsDelay * subsDelayMultiplier &&
        !subsCookie.a
      ) {
        handleOpenedSubscribe(true);
      } else {
        setOpenedSubscribe(false);
      }
    } else {
      handleOpenedSubscribe(true);
      persistInLocalStorage({ t1: new Date().getTime(), a: false });
    }
  }, [handleOpenedSubscribe]);

  useEffect(() => {
    if (isValid && isSubmitted && isSubmittedTouched) {
      sendMessage();
    }
  }, [isValid, isSubmitted, isSubmittedTouched, sendMessage]);

  useEffect(() => {
    if (subscribeModalEnabled) {
      showSubscribe();
    }
  }, [subscribeModalEnabled, showSubscribe]);

  return (
    <Fragment>
      {openedSubscribeTransition && (
        <div className={classes.wrapper}>
          <Modal
            className={`${classes["modal-container"]}`}
            transitionClassName={
              openedSubscribe
                ? classes["modal-transition-on"]
                : classes["modal-transition-off"]
            }
            show={true}
            onClick={closeSubscribe}
          >
            <div className={`${classes["modal"]}`}>
              <button className={classes.close} onClick={closeSubscribe}>
                x
              </button>
              {subscribed && (
                <div className={classes.content}>
                  <SubscribeModalTemplate
                    subscribeModalType={subscribeModalType}
                    section="end"
                    email={email}
                  />
                  <Button
                    disabled={false}
                    onClick={closeSubscribe}
                    className={classes.subscribe}
                  >
                    CLOSE
                  </Button>
                </div>
              )}
              {!subscribed && (
                <div className={classes.content}>
                  <SubscribeModalTemplate
                    subscribeModalType={subscribeModalType}
                    section="start"
                  />
                  <div className={classes["input"]}>
                    <input
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                      name="email"
                      type="text"
                    />
                    {isSubmittedTouched &&
                      !ValidationHelper.validateEmail(email) && (
                        <p className={classes.warning}>
                          {t("form_please_complete_field")}
                        </p>
                      )}
                  </div>
                  <Button
                    disabled={false}
                    onClick={triggerSubscribe}
                    className={classes.subscribe}
                  >
                    SUBSCRIBE
                    {loading && <div className="loader">Loading...</div>}
                  </Button>
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}
    </Fragment>
  );
};

export default SubscribeModal;
