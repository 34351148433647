import React from "react";
import ContactForm from "../components/Layout/Contact/ContactForm";
import { useTranslation } from "react-i18next";
import classes from "./Contact.module.css";
import HelmetContent from "../components/Layout/HelmetContent";

const Contact = () => {
  const { t } = useTranslation();
  const frontEndUrl = process.env.REACT_APP_FRONTEND_URL;
  const helmetData = {
    helmetTitle: "Get in Touch with us | Talvi Wear",
    helmetContent:
      "We're more than just a clothing brand - we're a family. We care about our customers and are always here to listen to their needs and concerns. Get in touch today!",
    helmetUrl: frontEndUrl + "contact",
    helmetImage: "/logo192.jpg",
  };

  return (
    <div>
      <HelmetContent helmetData={helmetData} />
      <div className={classes.banner}>
        <div className={classes["banner-image"]} />
        <div className={classes["banner-inner"]}>
          <h1>{t("contact_title")}</h1>
        </div>
        <div className={classes.spacer}></div>
      </div>
      <div className={classes["main-section-container"]}>
        <div className={classes["main-section"]}>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contact;
