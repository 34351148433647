import { React, useContext } from "react";
import { useTranslation } from "react-i18next";
import CartContext from "../../../store/cart-context";
import classes from "./CartItem.module.css";

const CartItem = (props) => {
  const product = props.item.product;
  const selectedSize = props.item.selectedSize;
  const selectedColor = props.item.selectedColor;
  const quantity = props.item.quantity;
  const cartCtx = useContext(CartContext);
  const { t } = useTranslation();

  return (
    <div className={classes["cart-item-container"]}>
      <div>
        <div>
          <strong>{product.name}</strong>
        </div>
        <div>
          {selectedSize} - {selectedColor} x{quantity}
        </div>
        <div className={classes["add-substract-buttons"]}>
          <p>
            <span onClick={() => cartCtx.addItem(props.item)}>
              {t("checkout_order_add")}
            </span>{" "}
            |{" "}
            <span onClick={() => cartCtx.removeItem(props.item)}>
              {t("checkout_order_remove")}
            </span>
          </p>
        </div>
      </div>
      <div></div>
      <div className={classes["subtotal"]}>
        <p>${(product.price * quantity).toFixed(2)}</p>
      </div>
    </div>
  );
};

export default CartItem;
