import React, { Fragment } from "react";
import ValidationHelper from "../../helpers/ValidationHelper";

const SubscribeModalTemplate = (props) => {
  const modalTypeNumber = ValidationHelper.validateNumber(
    props.subscribeModalType
  )
    ? props.subscribeModalType
    : 0;
  const couponPct = process.env.REACT_APP_COUPON_PCT_1 * 100;

  const modalTypes = (index) => {
    const content =
      props.section === "start"
        ? [
            <Fragment>
              <h1>Subscribe!</h1>
              <p>
                Enter your email below and subscribe to keep up to date with our
                promotions:
              </p>
            </Fragment>,
            <Fragment>
              <h1>Get a {couponPct}% discount now!</h1>
              <p>
                Enter your email below and subscribe to receive your discount
                code:
              </p>
            </Fragment>,
          ]
        : [
            <Fragment>
              <h2>Thank you for subscribing!</h2>
            </Fragment>,
            <Fragment>
              <h2>Thank you for subscribing!</h2>
              <h3>
                Your {couponPct}% discount code has been sent to{" "}
                <b>{props.email}</b>
              </h3>
            </Fragment>,
          ];
    return content[index];
  };

  return modalTypes(modalTypeNumber);
};

export default SubscribeModalTemplate;
