const FunctionsHelper = {
  formatDateOld: (date) => {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${da}/${mo}/${ye}`;
  },

  formatCurrency: (amount) => {
    return amount
      .toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
      })
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  },

  formatDate: (date) => {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return [
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
      date.getFullYear(),
    ].join("/");
  },

  returnItemId: (id, size, color) => {
    const concatValues = id.toString() + size + color;
    let result;
    switch (concatValues) {
      case "1Sblack":
        result = 1;
        break;
      case "1Mblack":
        result = 2;
        break;
      case "1Lblack":
        result = 3;
        break;
      case "1XLblack":
        result = 4;
        break;
      case "1XXLblack":
        result = 5;
        break;
      case "1Snatural":
        result = 6;
        break;
      case "1Mnatural":
        result = 7;
        break;
      case "1Lnatural":
        result = 8;
        break;
      case "1XLnatural":
        result = 9;
        break;
      case "1XXLnatural":
        result = 10;
        break;
      case "2Sblack":
        result = 11;
        break;
      case "2Mblack":
        result = 12;
        break;
      case "2Lblack":
        result = 13;
        break;
      case "2XLblack":
        result = 14;
        break;
      case "2XXLblack":
        result = 15;
        break;
      case "2Snatural":
        result = 16;
        break;
      case "2Mnatural":
        result = 17;
        break;
      case "2Lnatural":
        result = 18;
        break;
      case "2XLnatural":
        result = 19;
        break;
      case "2XXLnatural":
        result = 20;
        break;
      default:
        result = 0;
        break;
    }
    return result;
  },
};

export default FunctionsHelper;
