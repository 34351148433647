import React from "react";
import { Link } from "react-router-dom";
import Button from "../components/UI/Button";
import { useTranslation } from "react-i18next";
import Image1 from "../assets/img/home-img-1.webp";
import Image2 from "../assets/img/home-img-2.webp";
import Image3 from "../assets/img/home-img-3.webp";
import Image4 from "../assets/img/home-img-4.webp";
import classes from "./Home.module.css";
import GoogleAnalyticsTracker from "./../helpers/GoogleAnalyticsTracker";
import HelmetContent from "../components/Layout/HelmetContent";

const Home = () => {
  const { t } = useTranslation();
  const frontEndUrl = process.env.REACT_APP_FRONTEND_URL;
  const environment = process.env.REACT_APP_GOOGLE_ANALYTICS_ENV + " - ";
  const gaService = GoogleAnalyticsTracker(environment + "Home");
  const helmetData = {
    helmetTitle: "Talvi Wear Apparel | Outdoor Clothing",
    helmetContent:
      "Introducing the ultimate winter jacket: Talvi Wear, your steadfast companion in the face of chilly weather!",
    helmetUrl: frontEndUrl,
    helmetImage: "/logo192.jpg",
  };

  return (
    <div>
      <HelmetContent helmetData={helmetData} />
      <div className={classes.banner}>
        <div className={classes["banner-image"]} />
        <div className={classes["banner-inner"]}>
          <h1>
            {t("home_banner_1")}
            <br />
            {t("home_banner_2")}
          </h1>
          <p>{t("home_banner_3")}</p>
          <Link
            onClick={() => {
              gaService.eventTracker("Buy Now");
              window.ttq.track("ClickButton");
            }}
            to="/products"
            className={classes["buy-now"]}
          >
            {t("home_banner_4")}
          </Link>
        </div>
      </div>
      <div className={classes["main-section-container"]}>
        <div className={classes["main-section"]}>
          <div className={classes["detail"]}>
            <div className={classes["img-container-left"]}>
              <div className={classes["img-real"]}>
                <img
                  src={Image1}
                  style={{ objectFit: "cover" }}
                  className={classes["img-real-content"]}
                  alt="Talvi Wear's Winter Jacket in Natural Color"
                />
              </div>
            </div>

            <div className={classes["desc"]}>
              <h1>{t("home_banner_5")}</h1>
              <p>{t("home_banner_6")}</p>
              <Link
                onClick={() => {
                  gaService.eventTracker("Learn More");
                  window.ttq.track("ClickButton");
                }}
                to="/products"
              >
                <Button>{t("home_banner_7")}</Button>
              </Link>
            </div>
          </div>
          <div className={classes["detail"]}>
            <div className={classes["desc"]}>
              <h1>{t("home_banner_8")}</h1>
              <p>{t("home_banner_9")}</p>
            </div>
            <div className={classes["img-container-right"]}>
              <div className={classes["img-real"]}>
                <img
                  src={Image2}
                  style={{ objectFit: "cover" }}
                  className={classes["img-real-content"]}
                  alt="Talvi Wear Winter Jackets in Two Colors"
                />
              </div>
            </div>
          </div>
          <div className={classes["detail"]}>
            <div className={classes["img-container-left"]}>
              <div className={classes["img-real"]}>
                <img
                  src={Image3}
                  style={{ objectFit: "cover" }}
                  className={classes["img-real-content"]}
                  alt="Talvi Wear Winter Jacket Warm Inside"
                />
              </div>
            </div>

            <div className={classes["desc"]}>
              <h1>{t("home_banner_10")}</h1>
              <p>{t("home_banner_11")}</p>
            </div>
          </div>
          <div className={classes["detail"]}>
            <div className={classes["desc"]}>
              <h1>{t("home_banner_12")}</h1>
              <p>{t("home_banner_13")}</p>
            </div>
            <div className={classes["img-container-right"]}>
              <div className={classes["img-real"]}>
                <img
                  src={Image4}
                  style={{ objectFit: "cover" }}
                  className={classes["img-real-content"]}
                  alt="Talvi Wear Winter Jacket in Black & Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
