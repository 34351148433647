import React from "react";
import { Helmet } from "react-helmet";

const HelmetContent = (props) => {
  const helmetTitle = props.helmetData.helmetTitle;
  const helmetContent = props.helmetData.helmetContent;
  const helmetUrl = props.helmetData.helmetUrl;
  const helmetImage = props.helmetData.helmetImage;
  const helmetZoom = props.helmetData.helmetZoom;

  return (
    <Helmet>
      <title>{helmetTitle}</title>
      <meta name="title" content={helmetTitle} />
      <meta name="description" content={helmetContent} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={helmetUrl} />
      <meta property="og:title" content={helmetTitle} />
      <meta property="og:description" content={helmetContent} />
      <meta property="og:image" content={helmetImage} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={helmetUrl} />
      <meta property="twitter:title" content={helmetTitle} />
      <meta property="twitter:description" content={helmetContent} />
      <meta property="twitter:image" content={helmetImage} />
      {!helmetZoom && (
        <meta name="viewport" content="width=device-width, user-scalable=no" />
      )}
    </Helmet>
  );
};

export default HelmetContent;
