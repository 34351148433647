import React from "react";
import classes from "./Detail.module.css";

const Detail = (props) => {
  return (
    <div className={classes["product-details"]}>
      <h4 className={classes["product-details-header"]}>{props.info.title}</h4>
      <p className={classes["product-details-text"]}>{props.info.text}</p>
      {props.info.bullets && (
        <ul className={classes["product-details-bullets"]}>
          {props.info.bullets.map((i) => (
            <li>{i}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Detail;
