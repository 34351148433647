import React from "react";

const Shipment = () => {
  const region = "MLA";
  const zipCodeFrom = "1765";
  const zipCodeTo = "1091";
  const dimensions = "10x40x50,1500";
  const url = `https://api.mercadolibre.com/sites/${region}/shipping_options?zip_code_from=${zipCodeFrom}&zip_code_to=${zipCodeTo}&dimensions=${dimensions}`;

  fetch(url, {
    headers: {
      Authorization: "TG-60c56a8966ee2e0007f06b6b-771546085",
    },
  })
    .then((res) => res.json())
    .then((data) => console.log(data));

  return <div></div>;
};

export default Shipment;
