import React, { Suspense } from "react";
import { hydrate, render } from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./store/auth-context";
import { CartContextProvider } from "./store/cart-context";
import { LanguageContextProvider } from "./store/language-context";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import "./index.css";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "es"],
    fallbackLng: "es",
    detection: {
      order: ["localStorage", "cookie", "htmlTag", "path", "subdomain"],
      cache: ["localStorage", "cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    react: { useSuspense: false },
  });

const loadingMarkup = (
  <div>
    <h2>Loading...</h2>
  </div>
);

const APP = (
  <React.StrictMode>
    <AuthContextProvider>
      <CartContextProvider>
        <LanguageContextProvider>
          <BrowserRouter>
            <Suspense fallback={loadingMarkup}>
              <App />
            </Suspense>
          </BrowserRouter>
        </LanguageContextProvider>
      </CartContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}
