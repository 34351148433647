import React, { useState, useCallback, useEffect } from "react";
import { Urls } from "../enums/Constants";
import { useTranslation } from "react-i18next";
import classes from "./Blog.module.css";
import HelmetContent from "../components/Layout/HelmetContent";
import { useHistory } from "react-router-dom";

const Blog = () => {
  const history = useHistory();
  const [validPosts, setValidPosts] = useState(false);
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [posts, setPosts] = useState(null);
  const { t } = useTranslation();
  const frontEndUrl = process.env.REACT_APP_FRONTEND_URL;
  const helmetData = {
    helmetTitle: "Talvi Wear Blog",
    helmetContent: "Articles that will help you in many ways",
    helmetUrl: frontEndUrl,
    helmetImage: "/logo192.jpg",
  };

  const getPosts = useCallback(() => {
    fetch(`${Urls.backEndUrl}/get_posts`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data) {
          setPosts(data.posts);
          setValidPosts(true);
          setLoadingPosts(false);
        }
      })
      .catch(function () {
        setLoadingPosts(false);
        setValidPosts(false);
      });
  }, []);

  const convertDate = (date) => {
    return (
      date.toLocaleString("default", { month: "long" }) +
      " " +
      date.getDate() +
      ", " +
      (date.getYear() + 1900)
    ).toUpperCase();
  };

  useEffect(() => {
    history.replace(
      "/blog/the-ultimate-guide-to-choosing-the-perfect-winter-jacket"
    );
    getPosts();
  }, [getPosts, history]);

  return (
    <div>
      <HelmetContent helmetData={helmetData} />
      <div className={classes.banner}>
        <div className={classes["banner-image"]} />
        <div className={classes["banner-inner"]}>
          <h1>{t("blog_title")}</h1>
        </div>
        <div className={classes.spacer}></div>
      </div>
      <div className={classes["main-section-container"]}>
        <div className={classes["main-section"]}>
          {validPosts && (
            <div className={classes["post-container"]}>
              {posts.map((post) => {
                let content = post.content;
                if (post.images) {
                  post.images.forEach((image) => {
                    content = content.replace(
                      `{{image-${image.imageIndex}}}`,
                      `<div class=${classes["img-container"]}><img src="${image.imagePath}" /></div>`
                    );
                  });
                }
                const date = new Date(post.date._seconds * 1000);
                return (
                  <div>
                    <div className={classes["date"]}>{convertDate(date)}</div>
                    <div
                      className={classes["post"]}
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {loadingPosts && <div className="loader">Loading...</div>}
          {!loadingPosts && !validPosts && (
            <div className={classes["no-posts"]}>No available posts</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blog;
