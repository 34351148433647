import React, { useContext, useState, useEffect } from "react";
import { auth, database, emailAuthProvider } from "../firebase";

const AuthContext = React.createContext({
  currentUser: null,
  login: () => {},
  signup: () => {},
  logout: () => {},
  resetPassword: () => {},
  updateUser: () => {},
  updatePassword: async () => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const createUser = async (user, userInfo) => {
    database.users
      .doc(user.uid)
      .set({
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        email: user.email,
        email_sent: false,
        user_id: user.uid,
      })
      .then(() => {})
      .catch((error) => {});
  };

  const signup = async (userInfo) => {
    try {
      const userCredential = await auth.createUserWithEmailAndPassword(
        userInfo.email,
        userInfo.password
      );
      const user = userCredential.user;
      createUser(user, userInfo);
      return user;
    } catch (error) {
      return error;
    }
  };

  const login = async (userInfo) => {
    try {
      const result = await auth.signInWithEmailAndPassword(
        userInfo.email,
        userInfo.password
      );
      return { success: true, result: { result } };
    } catch (error) {
      return { success: false, result: { error } };
    }
  };

  const logout = () => {
    return auth.signOut();
  };

  const resetPassword = async (email) => {
    try {
      const result = await auth.sendPasswordResetEmail(email);
      return { success: true, result: { result } };
    } catch (error) {
      return { success: false, result: { error } };
    }
  };

  const updateUser = async (userInfo) => {
    try {
      const credentials = emailAuthProvider.credential(
        currentUser.email,
        userInfo.password
      );
      const userCredential = await currentUser.reauthenticateWithCredential(
        credentials
      );
      const dbCurrentUser = database.users.doc(userCredential.user.uid);
      const dbResult = await dbCurrentUser.update({
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        email: userInfo.email,
      });
      let fResult;
      if (userCredential.user.email !== userInfo.email) {
        fResult = await userCredential.user.updateEmail(userInfo.email);
      }
      return { success: true, dbResult, fResult };
    } catch (error) {
      return { success: false, error };
    }
  };

  const updatePassword = async (oldPassword, newPassword) => {
    try {
      const userCredential = await auth.signInWithEmailAndPassword(
        currentUser.email,
        oldPassword
      );
      const result = await userCredential.user.updatePassword(newPassword);
      return result;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    const unsuscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsuscribe;
  }, []);

  useEffect(() => {
    const sendVerification = async () => {
      if (currentUser) {
        const user = await database.users.doc(currentUser.uid).get();
        if (user.exists) {
          const data = user.data();
          if (!data.email_sent) {
            await currentUser.sendEmailVerification();
            database.users.doc(currentUser.uid).update({
              email_sent: true,
            });
          }
        }
      }
    };
    sendVerification();
  }, [currentUser]);

  const contextValue = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateUser,
    updatePassword,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
