import React, { useContext } from "react";
import classes from "./CartItem.module.css";
import CartContext from "../../../store/cart-context";
import Button from "../../UI/Button";

const CartItem = (props) => {
  const product = props.item.product;
  const selectedSize = props.item.selectedSize;
  const selectedColor = props.item.selectedColor;
  const productImage = props.item.productImage;
  const quantity = props.item.quantity;
  const cartCtx = useContext(CartContext);

  return (
    <div className={classes["item-container"]}>
      <div
        className={classes["item-image"]}
        style={{ backgroundImage: `url(${productImage})` }}
      />
      <div className={classes["product-information"]}>
        <h4>{product.name}</h4>
        <p>
          {selectedSize} - {selectedColor}
        </p>
      </div>
      <div className={classes["unit-cost-qty"]}>
        <h4>${product.price}</h4>
        <p>
          <em>Qty. {quantity}</em>
        </p>
      </div>
      <div className={classes.controls}>
        <div className={classes["add-substract-buttons"]}>
          <Button onClick={() => cartCtx.addItem(props.item)}>+</Button>
          <Button onClick={() => cartCtx.removeItem(props.item)}>-</Button>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
