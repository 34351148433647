import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./About.module.css";
import HelmetContent from "../components/Layout/HelmetContent";

const About = () => {
  const { t } = useTranslation();
  const frontEndUrl = process.env.REACT_APP_FRONTEND_URL;
  const helmetData = {
    helmetTitle: "About Us | The Talvi Wear Story",
    helmetContent:
      "At Talvi Wear, we are more than just a clothing brand - we are a family. When you purchase one of our products, you become a part of our community, where your voice is heard and your needs are cared for",
    helmetUrl: frontEndUrl + "about",
    helmetImage: "/logo192.jpg",
  };

  return (
    <div>
      <HelmetContent helmetData={helmetData} />
      <div className={classes.banner}>
        <div className={classes["banner-image"]} />
        <div className={classes["banner-inner"]}>
          <h1>{t("about_title")}</h1>
        </div>
        <div className={classes.spacer}></div>
      </div>
      <div className={classes["main-section-container"]}>
        <div className={classes["main-section"]}>
          <h2>{t("about_1")}</h2>
          <p>{t("about_2")}</p>
          <p>{t("about_3")}</p>
          <p>{t("about_4")}</p>
          <p>{t("about_5")}</p>
          <p>{t("about_6")}</p>
          <p>{t("about_7")}</p>
        </div>
      </div>
    </div>
  );
};

export default About;
