import React from "react";
import classes from "./Size.module.css";

const Size = (props) => {
  const selectedClass = classes["selected-size"];
  const sizes = props.sizes;

  const onSizeSelect = (selectedSize) => {
    props.onClick(selectedSize);
  };

  return (
    <div className={classes["size-container"]}>
      {sizes.map((size) => (
        <button
          key={size}
          className={props.selectedSize === size ? selectedClass : ""}
          onClick={() => onSizeSelect(size)}
        >
          {size}
        </button>
      ))}
    </div>
  );
};

export default Size;
