import React, { useState, useEffect } from "react";
import Button from "../../UI/Button";
import { useTranslation } from "react-i18next";
import ValidationHelper from "../../../helpers/ValidationHelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "./ChangePassword.module.css";

const notifyInfo = (message) =>
  toast.info(message, {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const notifyError = (message) =>
  toast.error(message, {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const ChangePassword = (props) => {
  const [isSubmittedTouched, setIsSubmittedTouched] = useState();
  const [isSubmitted, setIsSubmitted] = useState();
  const [isValid, setIsValid] = useState(false);
  const [passwords, setPasswords] = useState({
    password: "",
    newPassword: "",
    newPasswordConfirm: "",
  });
  const { t } = useTranslation();

  const validateForm = () => {
    const valid =
      ValidationHelper.validateSimpleText(passwords.password) &&
      ValidationHelper.validateSimpleText(passwords.newPassword) &&
      ValidationHelper.validateSimpleText(passwords.newPasswordConfirm) &&
      passwordsMatch();
    setIsValid(valid);
  };

  const passwordsMatch = () => {
    return passwords.newPassword === passwords.newPasswordConfirm;
  };

  const changePasswordHandler = () => {
    setIsSubmittedTouched(true);
    validateForm();
  };

  useEffect(() => {
    const updatePasswordInner = async () => {
      try {
        if (isValid && isSubmittedTouched) {
          const result = await props.updatePassword(
            passwords.password,
            passwords.newPassword
          );
          if (!result) {
            notifyInfo(t("profile_change_password_notify_success"));
          } else {
            notifyError(t("profile_change_password_notify_error"));
          }
          setPasswords({
            password: "",
            newPassword: "",
            newPasswordConfirm: "",
          });
          setIsSubmittedTouched(false);
          return result;
        }
      } catch (error) {
        return error;
      }
    };
    updatePasswordInner();
    setIsValid(false);
  }, [
    isValid,
    isSubmitted,
    passwords.password,
    passwords.newPassword,
    props,
    t,
    isSubmittedTouched,
  ]);

  return (
    <div className={classes["main-section-container"]}>
      <div className={classes["main-section"]}>
        <h2>{t("profile_change_password")}</h2>
        <div className={classes["input"]}>
          <label htmlFor="password"> {t("profile_password")}</label>
          <input
            onChange={(e) => {
              setIsSubmitted(false);
              setPasswords((prevState) => ({
                ...prevState,
                password: e.target.value,
              }));
            }}
            value={passwords.password}
            type="password"
            name="password"
          />
          {isSubmittedTouched &&
            !ValidationHelper.validateSimpleText(passwords.password) && (
              <p className={classes.warning}>
                {t("form_please_complete_field")}
              </p>
            )}
        </div>
        <div className={classes["input"]}>
          <label htmlFor="new_password">{t("profile_new_password")}</label>
          <input
            onChange={(e) => {
              setIsSubmitted(false);
              setPasswords((prevState) => ({
                ...prevState,
                newPassword: e.target.value,
              }));
            }}
            value={passwords.newPassword}
            type="password"
            name="new_password"
          />
          {isSubmittedTouched &&
            !ValidationHelper.validateSimpleText(passwords.newPassword) && (
              <p className={classes.warning}>
                {t("form_please_complete_field")}
              </p>
            )}
        </div>
        <div className={classes["input"]}>
          <label htmlFor="new_password_confirm">
            {t("profile_new_password_confirm")}
          </label>
          <input
            onChange={(e) => {
              setIsSubmitted(false);
              setPasswords((prevState) => ({
                ...prevState,
                newPasswordConfirm: e.target.value,
              }));
            }}
            value={passwords.newPasswordConfirm}
            type="password"
            name="new_password_confirm"
          />
          {isSubmittedTouched &&
            !ValidationHelper.validateSimpleText(
              passwords.newPasswordConfirm
            ) && (
              <p className={classes.warning}>
                {t("form_please_complete_field")}
              </p>
            )}
        </div>
        <Button onClick={changePasswordHandler}>
          {t("profile_my_account_update")}
        </Button>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </div>
  );
};

export default ChangePassword;
