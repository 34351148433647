import ReactPixel from "react-facebook-pixel";

const FacebookTracker = () => {
  const eventTracker = (action = "test action", data = {}) => {
    ReactPixel.track(action, data);
  };

  const fbService = { eventTracker };
  return fbService;
};

export default FacebookTracker;
