import React, { Fragment, useState, useEffect, useContext } from "react";
import CartContext from "../store/cart-context";
import Button from "../components/UI/Button";
import { Redirect } from "react-router-dom";
import { Urls } from "../enums/Constants";
import { useTranslation } from "react-i18next";
import classes from "./Success.module.css";
import GoogleAnalyticsTracker from "./../helpers/GoogleAnalyticsTracker";
import FacebookTracker from "./../helpers/FacebookTracker";
import HelmetContent from "../components/Layout/HelmetContent";

const Success = (props) => {
  const cartCtx = useContext(CartContext);
  const [cartEmpty, setCartEmpty] = useState(false);
  const [redirectSuccess, setRedirectSuccess] = useState(false);
  const [sessionId, setSessionId] = useState();
  const [order, setOrder] = useState(null);
  const [orderDate, setOrderDate] = useState();
  const { t } = useTranslation();
  const frontEndUrl = process.env.REACT_APP_FRONTEND_URL;
  const environment = process.env.REACT_APP_GOOGLE_ANALYTICS_ENV + " - ";
  const gaService = GoogleAnalyticsTracker(environment + "Success");
  const fbService = FacebookTracker(environment + "Success");
  const helmetData = {
    helmetTitle: "Thank you for your purchase!",
    helmetContent:
      "Introducing the ultimate winter jacket: Talvi Wear, your steadfast companion in the face of chilly weather!",
    helmetUrl: frontEndUrl,
    helmetImage: "/logo192.jpg",
  };

  useEffect(() => {
    setSessionId(new URLSearchParams(props.location.search).get("session_id"));
  }, [props.location.search]);

  useEffect(() => {
    if (sessionId && !order) {
      fetch(`${Urls.backEndUrl}/get-session-info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sessionId: sessionId,
          userId: "1",
        }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          return res.json().then((json) => Promise.reject(json));
        })
        .then((order) => {
          // console.log(order.order);
          setOrder(order.order);
          gaService.eventTracker("Purchase");
          fbService.eventTracker("Purchase", {
            value: order.order.payment.total.toFixed(2),
            currency: "USD",
          });
        })
        .catch((e) => {
          console.error(e.error);
          alert("Unexpected error");
        });
    }
  }, [sessionId, order, gaService, fbService]);

  useEffect(() => {
    if (!!order) {
      let t = new Date(1970, 0, 0);
      t.setSeconds(order.createdDate._seconds);
      setOrderDate(t);
    }
  }, [order]);

  const emptyCartLocalStorage = () => {
    return (
      <Fragment>
        <HelmetContent helmetData={helmetData} />
        <div className={classes.banner}>
          <div className={classes["banner-image"]} />
          <div className={classes["banner-inner"]}>
            <h1>{t("success_title")}</h1>
          </div>
          <div className={classes.spacer}></div>
        </div>
        <div className={classes["main-section-container"]}>
          <div className={classes["main-section"]}>
            <h2>Thank you for your purchase!</h2>

            <div className={classes["order-content"]}>
              <div className={classes["order-content-header"]}>
                <h2>
                  {t("profile_orders_order_modal_1")} #{order && order.orderId}
                </h2>
              </div>
              {order && (
                <div className={classes["order-content-info"]}>
                  <h3>{t("profile_orders_order_modal_10")}</h3>
                  <p>
                    {t("profile_orders_order_modal_2")}:{" "}
                    {orderDate && orderDate.toLocaleDateString("en-US")}
                  </p>
                  <p>
                    {t("profile_orders_order_modal_3")}:{" "}
                    {order.status === "complete" &&
                      t("profile_orders_approved")}
                    {order.status === "open" && t("profile_orders_in_process")}
                    {order.status === "expired" && t("profile_orders_rejected")}
                  </p>
                  <h3>{t("profile_orders_order_modal_4")}</h3>
                  <ul>
                    {order.items.map((item) => (
                      <li key={item.item.sku}>
                        {item.item.name} (x{item.quantity}){" "}
                        <b>(${item.item.price})</b>
                      </li>
                    ))}
                  </ul>
                  <p>
                    {t("profile_orders_order_modal_5")}: {order.shipment}
                  </p>
                  <h3>{t("profile_orders_order_modal_11")}</h3>
                  <p>
                    {t("profile_orders_order_modal_6")}:{" "}
                    <b>${order.payment.subtotal.toFixed(2)}</b>
                  </p>
                  <p>
                    {t("profile_orders_order_modal_7")}:{" "}
                    <b>${order.payment.shipping.toFixed(2)}</b>
                  </p>
                  {order.payment.discount > 0 && (
                    <p>
                      {t("profile_orders_order_modal_12")}:{" "}
                      <b>${order.payment.discount.toFixed(2)}</b>
                    </p>
                  )}
                  <p>
                    {t("profile_orders_order_modal_8")}:{" "}
                    <b>${order.payment.tax.toFixed(2)}</b>
                  </p>
                  <p>
                    <strong>{t("profile_orders_order_modal_9")}: </strong>
                    <b>${order.payment.total.toFixed(2)}</b>
                  </p>
                </div>
              )}
            </div>
            <Button onClick={() => setRedirectSuccess(true)}>
              BACK TO HOME
            </Button>
          </div>
        </div>
        {redirectSuccess && <Redirect to="/" />}
      </Fragment>
    );
  };

  useEffect(() => {
    if (!cartEmpty) {
      setCartEmpty(true);
      cartCtx.emptyCart();
      localStorage.removeItem("talviCart");
    }
  }, [cartCtx, cartEmpty]);

  return emptyCartLocalStorage();
};

export default Success;
