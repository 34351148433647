import React, { useState, useEffect, useCallback } from "react";
import Modal from "../../../UI/Modal";
import { Urls } from "../../../../enums/Constants";
import FunctionsHelper from "../../../../helpers/FunctionsHelper";
import { useTranslation } from "react-i18next";
import classes from "./OrderModal.module.css";

const OrderModal = (props) => {
  const orderId = props.orderId;
  const [fetchingOrder, setFetchingOrder] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { t } = useTranslation();

  const getOrder = useCallback(
    (orderId) => {
      if (fetchingOrder) {
        setFetchingOrder(false);
        fetch(`${Urls.backEndUrl}/get_order?orderId=${orderId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            // console.log(data.order);
            if (data) {
              setSelectedOrder(data.order);
            }
          })
          .catch(function () {
            alert("Order not found");
          });
      }
    },
    [fetchingOrder]
  );

  useEffect(() => {
    getOrder(orderId);
  }, [getOrder, orderId]);

  return (
    <Modal
      className={classes["modal-container"]}
      show={props.openModal}
      onClick={props.closeModal}
    >
      <div className={classes["modal"]}>
        <button className={classes.close} onClick={props.closeModal}>
          x
        </button>
        <div className={classes["modal-content"]}>
          <h2>
            {t("profile_orders_order_modal_1")} #{props.orderId}
          </h2>
          {selectedOrder && (
            <div className={classes["order-content"]}>
              <p>
                {t("profile_orders_order_modal_2")}:{" "}
                {FunctionsHelper.formatDate(
                  new Date(selectedOrder.createdDate._seconds * 1000)
                )}
              </p>
              <p>
                {t("profile_orders_order_modal_3")}:{" "}
                {selectedOrder.status === "complete" &&
                  t("profile_orders_approved")}
                {(selectedOrder.status === "open" ||
                  selectedOrder.status === "pending") &&
                  t("profile_orders_in_process")}
                {selectedOrder.status === "expired" &&
                  t("profile_orders_rejected")}
              </p>
              <h3>{t("profile_orders_order_modal_4")}</h3>
              <ul>
                {selectedOrder.items.map((item) => (
                  <li key={item.item.sku}>
                    {item.item.name} (x{item.item.name}){" "}
                    <b>(${item.item.price})</b>
                  </li>
                ))}
              </ul>
              <p>
                {t("profile_orders_order_modal_5")}: {selectedOrder.shipment}
              </p>
              <p>
                {t("profile_orders_order_modal_6")}:{" "}
                <b>${selectedOrder.payment.subtotal.toFixed(2)}</b>
              </p>
              <p>
                {t("profile_orders_order_modal_7")}:{" "}
                <b>${selectedOrder.payment.shipping.toFixed(2)}</b>
              </p>
              <p>
                {t("profile_orders_order_modal_8")}:{" "}
                <b>${selectedOrder.payment.tax.toFixed(2)}</b>
              </p>
              <p>
                <b>{t("profile_orders_order_modal_9")}: </b>
                <b>${selectedOrder.payment.total.toFixed(2)}</b>
              </p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OrderModal;
