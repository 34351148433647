import React, { Fragment } from "react";
import Product from "../components/Layout/Product/Product";
import { Sizes, Colors } from "../enums/Constants";
import { useTranslation } from "react-i18next";
import classes from "./Products.module.css";
import HelmetContent from "../components/Layout/HelmetContent";

const Products = () => {
  const { t } = useTranslation();
  const frontEndUrl = process.env.REACT_APP_FRONTEND_URL;

  const PRODUCT_DATA = [
    {
      id: 1,
      name: t("product_1_name"),
      details: [
        {
          title: t("product_1_details_1_title"),
          text: t("product_1_details_1_description"),
        },
        {
          title: t("product_1_details_2_title"),
          text: t("product_1_details_2_description"),
        },
        {
          title: t("product_1_details_3_title"),
          text: t("product_1_details_3_description"),
        },
        {
          title: t("product_1_details_4_title"),
          text: t("product_1_details_4_description"),
        },
        {
          title: t("product_1_details_5_title"),
          text: t("product_1_details_5_description"),
        },
        {
          title: t("product_1_details_6_title"),
          text: t("product_1_details_6_description"),
        },
      ],
      price: 119.99,
      quantity: 1,
      sizes: [Sizes.S, Sizes.M, Sizes.L, Sizes.XL, Sizes.XXL],
      colors: [
        {
          color: Colors.black,
          imgUrls: [
            "mens-talvi-jacket-black-side.webp",
            "mens-talvi-jacket-black-front.webp",
            "mens-talvi-jacket-black-back.webp",
            "mens-talvi-jacket-measures-en.webp",
          ],
          inventoryCode: "mens-talvi-jacket-black",
        },
        {
          color: Colors.natural,
          imgUrls: [
            "mens-talvi-jacket-natural-side.webp",
            "mens-talvi-jacket-natural-front.webp",
            "mens-talvi-jacket-natural-back.webp",
            "mens-talvi-jacket-measures-en.webp",
          ],
          inventoryCode: "mens-talvi-jacket-natural",
        },
      ],
      rating: 5,
      gender: "m",
    },
    {
      id: 2,
      name: t("product_2_name"),
      details: [
        {
          title: t("product_2_details_1_title"),
          text: t("product_2_details_1_description"),
        },
        {
          title: t("product_2_details_2_title"),
          text: t("product_2_details_2_description"),
        },
        {
          title: t("product_2_details_3_title"),
          text: t("product_2_details_3_description"),
        },
        {
          title: t("product_2_details_4_title"),
          text: t("product_2_details_4_description"),
        },
        {
          title: t("product_2_details_5_title"),
          text: t("product_2_details_5_description"),
        },
        {
          title: t("product_2_details_6_title"),
          text: t("product_2_details_6_description"),
        },
      ],
      price: 119.99,
      quantity: 1,
      sizes: [Sizes.S, Sizes.M, Sizes.L, Sizes.XL, Sizes.XXL],
      colors: [
        {
          color: Colors.black,
          imgUrls: [
            "womens-talvi-jacket-black-side.webp",
            "womens-talvi-jacket-black-front.webp",
            "womens-talvi-jacket-black-back.webp",
            "womens-talvi-jacket-measures-en.webp",
          ],
          inventoryCode: "womens-talvi-jacket-black",
        },
        {
          color: Colors.natural,
          imgUrls: [
            "womens-talvi-jacket-natural-side.webp",
            "womens-talvi-jacket-natural-front.webp",
            "womens-talvi-jacket-natural-back.webp",
            "womens-talvi-jacket-measures-en.webp",
          ],
          inventoryCode: "womens-talvi-jacket-natural",
        },
      ],
      rating: 5,
      gender: "f",
    },
  ];

  const helmetData = {
    helmetTitle: "Winter Jackets | Talvi Wear Clothing",
    helmetContent:
      "Our jackets are made with top-of-the-line materials and are built to last, browse our collection today and upgrade your winter wardrobe with Talvi Wear!",
    helmetUrl: frontEndUrl + "products",
    helmetImage: "/logo192.jpg",
  };

  return (
    <div>
      <HelmetContent helmetData={helmetData} />
      <div className={classes.banner}>
        <div className={classes["banner-image"]} />
        <div className={classes["banner-inner"]}>
          <h1>{t("products_title")}</h1>
        </div>
        <div className={classes.spacer}></div>
      </div>
      <div className={classes["main-section-container"]}>
        <div className={classes["main-section"]}>
          {PRODUCT_DATA.map((product) => (
            <Fragment key={product.id}>
              <div className={classes["product-container"]}>
                <Product item={product} className={classes.product} />
              </div>
              <div className={classes["product-spacer"]}></div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Products;
