export const Languages = { spanish: "es", english: "en" };
export const Sizes = { S: "S", M: "M", L: "L", XL: "XL", XXL: "XXL" };
export const Colors = {
  black: { colorName: "black", colorCode: "#111111" },
  natural: { colorName: "natural", colorCode: "#bfb5ac" },
};
export const States = {
  argentina: {
    states: [
      "Buenos Aires",
      "Capital Federal",
      "Catamarca",
      "Chaco",
      "Chubut",
      "Córdoba",
      "Corrientes",
      "Entre Ríos",
      "Formosa",
      "Jujuy",
      "La Pampa",
      "La Rioja",
      "Mendoza",
      "Misiones",
      "Neuquén",
      "Río Negro",
      "Salta",
      "San Juan",
      "San Luis",
      "Santa Cruz",
      "Santa Fe",
      "Santiago del Estero",
      "Tierra del Fuego",
      "Tucumán",
    ],
  },
  unitedStates: {
    states: [
      null,
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "FM",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MH",
      "MI",
      "MN",
      "MO",
      "MP",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "PW",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UM",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
  },
};

export const StateData = {
  unitedStates: {
    AK: { taxRate: 0, shippingRate: 11.7 },
    AL: { taxRate: 0.04, shippingRate: 8.25 },
    AR: { taxRate: 0.065, shippingRate: 8.86 },
    AZ: { taxRate: 0.056, shippingRate: 11.77 },
    CA: { taxRate: 0.0725, shippingRate: 11.7 },
    CO: { taxRate: 0.029, shippingRate: 11.02 },
    CT: { taxRate: 0.0635, shippingRate: 8.86 },
    DC: { taxRate: 0.06, shippingRate: 8.86 },
    DE: { taxRate: 0, shippingRate: 8.86 },
    FL: { taxRate: 0.06, shippingRate: 7.8 },
    GA: { taxRate: 0.04, shippingRate: 8.25 },
    HI: { taxRate: 0.04, shippingRate: 11.7 },
    IA: { taxRate: 0.06, shippingRate: 10.45 },
    ID: { taxRate: 0.06, shippingRate: 11.7 },
    IL: { taxRate: 0.0625, shippingRate: 8.86 },
    IN: { taxRate: 0.07, shippingRate: 8.86 },
    KS: { taxRate: 0.065, shippingRate: 10.45 },
    KY: { taxRate: 0.06, shippingRate: 8.86 },
    LA: { taxRate: 0.0445, shippingRate: 8.86 },
    MA: { taxRate: 0.0625, shippingRate: 10.45 },
    MD: { taxRate: 0.06, shippingRate: 8.86 },
    ME: { taxRate: 0.055, shippingRate: 10.45 },
    MI: { taxRate: 0.06, shippingRate: 10.45 },
    MN: { taxRate: 0.0688, shippingRate: 11.02 },
    MO: { taxRate: 0.0423, shippingRate: 8.86 },
    MS: { taxRate: 0.07, shippingRate: 8.86 },
    MT: { taxRate: 0, shippingRate: 11.7 },
    NC: { taxRate: 0.0475, shippingRate: 8.25 },
    ND: { taxRate: 0.05, shippingRate: 11.02 },
    NE: { taxRate: 0.055, shippingRate: 10.45 },
    NH: { taxRate: 0, shippingRate: 10.45 },
    NJ: { taxRate: 0.06625, shippingRate: 8.86 },
    NM: { taxRate: 0.0513, shippingRate: 11.02 },
    NV: { taxRate: 0.0685, shippingRate: 11.7 },
    NY: { taxRate: 0.04, shippingRate: 8.86 },
    OH: { taxRate: 0.0575, shippingRate: 8.86 },
    OK: { taxRate: 0.045, shippingRate: 10.45 },
    OR: { taxRate: 0, shippingRate: 11.7 },
    PA: { taxRate: 0.06, shippingRate: 8.86 },
    RI: { taxRate: 0.07, shippingRate: 10.45 },
    SC: { taxRate: 0.06, shippingRate: 8.25 },
    SD: { taxRate: 0.045, shippingRate: 11.02 },
    TN: { taxRate: 0.07, shippingRate: 8.25 },
    TX: { taxRate: 0.0625, shippingRate: 11.02 },
    UT: { taxRate: 0.0485, shippingRate: 11.7 },
    VA: { taxRate: 0.043, shippingRate: 8.86 },
    VT: { taxRate: 0.06, shippingRate: 10.45 },
    WA: { taxRate: 0.065, shippingRate: 11.7 },
    WI: { taxRate: 0.05, shippingRate: 10.45 },
    WV: { taxRate: 0.06, shippingRate: 8.86 },
    WY: { taxRate: 0.04, shippingRate: 11.7 },
  },
};

export const Urls = {
  backEndUrl: process.env.REACT_APP_BACKEND_URL,
  // backEndUrl: "https://talvi-project-back-end-dev.herokuapp.com",
};
