const ValidationHelper = {
  validateSimpleText: (text) => {
    return !!text && text.length > 0 && text.length < 50;
  },

  validateNumber: (text) => {
    return /^\d+$/.test(text);
  },

  validateIdentityNumber: (num) => {
    return num.length === 8 && ValidationHelper.validateNumber(num);
  },

  validateZipCode: (code) => {
    return code.length === 5 && ValidationHelper.validateNumber(code);
  },

  validatePhoneNumber: (phone) => {
    return /^\+?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{2,13}$/.test(phone);
  },

  validateEmail: (email) => {
    return /^[a-zA-Z0-9_.]+@[a-zA-Z0-9]+\.[A-Za-z]+(\.[A-Za-z]+)?$/.test(email);
  },

  isEmpty: (text) => {
    return text.length === 0;
  },
};

export default ValidationHelper;
