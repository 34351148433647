import React, { Fragment, useState, useContext } from "react";
import classes from "./Menu.module.css";
import logo from "../../assets/img/talvi-logo.png";
import argentinaFlag from "../../assets/img/argentina-flag.png";
import usaFlag from "../../assets/img/usa-flag.png";
import { ReactComponent as LogoutSvg } from "../../assets/svg/logout.svg";
import { ReactComponent as UserSvg } from "../../assets/svg/user.svg";
import { ReactComponent as CartSvg } from "../../assets/svg/shopping-cart.svg";
import { ReactComponent as CartSmallSvg } from "../../assets/svg/shopping-cart-small.svg";
import { ReactComponent as BurgerMenuSvg } from "../../assets/svg/burger-menu.svg";
import { NavLink, useLocation } from "react-router-dom";
import SideMenu from "./SideMenu";
import CartModal from "./Cart/CartModal";
import { useAuth } from "../../store/auth-context";
import CartContext from "../../store/cart-context";
import { useHistory } from "react-router";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import GoogleAnalyticsTracker from "./../../helpers/GoogleAnalyticsTracker";
import FacebookTracker from "./../../helpers/FacebookTracker";

const Menu = () => {
  const [openedSideMenu, setOpenedSideMenu] = useState(false);
  // const [openedCart, setOpenedCart] = useState(false);
  const [openedCart] = useState(false);
  const location = useLocation();
  const { currentUser, logout } = useAuth();
  const cartCtx = useContext(CartContext);
  const history = useHistory();
  const { t } = useTranslation();
  const environment = process.env.REACT_APP_GOOGLE_ANALYTICS_ENV + " - ";
  const gaService = GoogleAnalyticsTracker(environment + "Menu");
  const fbService = FacebookTracker(environment + "Menu");

  const userMenuClasses = `${classes["user-menu"]} ${
    !!currentUser ? classes["user-menu-logged"] : ""
  }`;

  const activeHomeClass =
    location.pathname === "/" || location.pathname === "/home"
      ? classes.active
      : "";

  const openSideMenu = () => {
    setOpenedSideMenu(true);
  };

  const closeSideMenu = () => {
    setOpenedSideMenu(false);
  };

  const openCart = () => {
    gaService.eventTracker("Checkout");
    fbService.eventTracker("InitiateCheckout");
    window.ttq.track("InitiateCheckout");
    history.push("./checkout");
    // setOpenedCart(true);
  };

  const closeCart = () => {
    // setOpenedCart(false);
  };

  const openProfile = () => {
    gaService.eventTracker("Profile");
    history.push("./profile");
    // setOpenedCart(true);
  };

  return (
    <Fragment>
      <SideMenu show={openedSideMenu} onCloseSideMenu={closeSideMenu} />
      {openedCart && (
        <CartModal openedCart={openedCart} closeCart={closeCart} />
      )}
      <div className={classes["top-menu"]}>
        <div className={classes["top-menu-inner"]}>
          <NavLink to="/" className={classes.logo}>
            <img src={logo} alt="Talvi Logo" />
          </NavLink>
          <div className={classes["top-menu-links"]}>
            <div className={classes["navigation-links"]}>
              <NavLink
                onClick={() => gaService.eventTracker("Home")}
                to="/home"
                className={activeHomeClass}
              >
                {t("menu_home")}
              </NavLink>
              <NavLink
                onClick={() => gaService.eventTracker("Blog")}
                to="/blog"
                activeClassName={classes.active}
              >
                {t("menu_blog")}
              </NavLink>
              <NavLink
                onClick={() => gaService.eventTracker("Products")}
                to="/products"
                activeClassName={classes.active}
              >
                {t("menu_products")}
              </NavLink>
              <NavLink
                onClick={() => gaService.eventTracker("About")}
                to="/about"
                activeClassName={classes.active}
              >
                {t("menu_about")}
              </NavLink>
              <NavLink
                onClick={() => gaService.eventTracker("Contact")}
                to="/contact"
                activeClassName={classes.active}
              >
                {t("menu_contact")}
              </NavLink>
            </div>
            <div className={userMenuClasses}>
              <div onClick={openProfile} className={classes.user}>
                <UserSvg />
              </div>
              {!!currentUser && (
                <div onClick={logout} className={classes.logout}>
                  <LogoutSvg />
                </div>
              )}
              <div className={classes.cart} onClick={openCart}>
                <CartSvg />
                {cartCtx.totalQuantity > 0 && (
                  <div className={classes["cart-badge"]}>
                    {cartCtx.totalQuantity}
                  </div>
                )}
              </div>
              <div className={classes["language"]}>
                <div
                  onClick={() => i18next.changeLanguage("es")}
                  style={{
                    backgroundImage: `url(${argentinaFlag})`,
                  }}
                />
                <div
                  onClick={() => i18next.changeLanguage("en")}
                  style={{
                    backgroundImage: `url(${usaFlag})`,
                  }}
                />
              </div>
            </div>
          </div>
          <div
            onClick={openProfile}
            className={`${classes["user-small"]} ${classes["small-menu"]}`}
          >
            <UserSvg />
          </div>
          <div
            className={`${classes["cart-small"]} ${classes["small-menu"]}`}
            onClick={openCart}
          >
            <CartSmallSvg />
            {cartCtx.totalQuantity > 0 && (
              <div className={classes["cart-badge"]}>
                {cartCtx.totalQuantity}
              </div>
            )}
          </div>
          <div className={`${classes["burger-menu"]} ${classes["small-menu"]}`}>
            <BurgerMenuSvg onClick={openSideMenu} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Menu;
