import React, { useState, useEffect } from "react";
import Button from "../../components/UI/Button";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ValidationHelper from "../../helpers/ValidationHelper";
import { useAuth } from "../../store/auth-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "./ResetPassword.module.css";

const notifyInfo = (message) =>
  toast.info(message, {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const ResetPassword = () => {
  const { resetPassword } = useAuth();
  const [isSubmittedTouched, setIsSubmittedTouched] = useState();
  const [isSubmitted, setIsSubmitted] = useState();
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState("");
  const history = useHistory();
  const { t } = useTranslation();

  const validateForm = () => {
    const valid = ValidationHelper.validateEmail(email);
    setIsValid(valid);
  };

  const resetPasswordHandler = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsSubmittedTouched(true);
    validateForm();
  };

  useEffect(() => {
    const resetPasswordInner = async () => {
      try {
        if (isValid && isSubmitted) {
          const result = await resetPassword(email);
          if (result.success || !result.success) {
            notifyInfo(t("profile_reset_password_notify"));
            setTimeout(() => history.replace("/profile"), 1500);
          }
          setEmail("");
          setIsSubmittedTouched(false);
          return result;
        }
      } catch (error) {
        return error;
      }
    };
    resetPasswordInner();
    setIsValid(false);
  }, [isValid, isSubmitted, email, t, resetPassword, history]);

  return (
    <div className={classes["reset-password-container"]}>
      <div className={classes["reset-password"]}>
        <div className={classes["reset-password-form"]}>
          <h2>{t("profile_reset_password_title")}</h2>
          <form>
            <div className={classes.control}>
              <label htmlFor="email">{t("profile_reset_password_1")}</label>
              <input
                onChange={(e) => {
                  setIsSubmitted(false);
                  setEmail(e.target.value);
                }}
                value={email}
                type="text"
                name="email"
              />
              {isSubmittedTouched && !ValidationHelper.validateEmail(email) && (
                <p className={classes.warning}>
                  {t("form_please_complete_field")}
                </p>
              )}
            </div>
            <Button type="submit" onClick={resetPasswordHandler}>
              {t("profile_reset_password_button")}
            </Button>
          </form>
        </div>
        <div></div>
        <div></div>
        <Link className={classes["go-back"]} to="/profile">
          {t("profile_reset_password_back")}
        </Link>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </div>
  );
};

export default ResetPassword;
