import React, { useState } from "react";
import { Languages } from "../enums/Constants";

const LanguageContext = React.createContext({
  language: "",
  changeLanguage: () => {},
});

const retrieveStoredLanguage = () => {
  let storedLanguage = localStorage.getItem("language");
  if (!storedLanguage) {
    storedLanguage = Languages.english;
  }
  return storedLanguage;
};

export const LanguageContextProvider = (props) => {
  const languageData = retrieveStoredLanguage();
  let initialLanguage;
  if (languageData) {
    initialLanguage = languageData;
  }
  const [language, setLanguage] = useState(initialLanguage);

  const languageHandler = (language) => {
    setLanguage(language);
    localStorage.setItem("language", language);
  };

  const contextValue = {
    language: language,
    changeLanguage: languageHandler,
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {props.children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
