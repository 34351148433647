import React, { useState, useContext, useEffect, useCallback } from "react";
import Modal from "../../UI/Modal";
import classes from "./MercadoPago.module.css";
import "./MercadoPago.css";
import CartContext from "../../../store/cart-context";

const url = process.env.REACT_APP_BACKEND_URL;
const PUBLIC_KEY = process.env.REACT_APP_ML_PUBLIC_KEY;

const MercadoPagoComponent = (props) => {
  const cartCtx = useContext(CartContext);
  const [loading, setLoading] = useState(false);

  const data = cartCtx.items.map((item) => ({
    title: item.product.name,
    description:
      item.product.name +
      " - " +
      item.selectedSize +
      " - " +
      item.selectedColor,
    pictureUrl: item.productImage,
    currencyId: "ARS",
    quantity: item.quantity,
    price: item.product.price,
  }));

  const [orderData] = useState(data);

  const createPayment = (preference) => {
    const mp = new window.MercadoPago(PUBLIC_KEY, {
      locale: "es-AR",
    });
    // Inicializa el checkout
    mp.checkout({
      preference: {
        id: preference,
      },
      render: {
        container: "#button-checkout", // Indica dónde se mostrará el botón de pago
        label: "Pay", // Cambia el texto del botón de pago (opcional)
      },
    });
    setLoading(false);
  };

  const createPreference = useCallback(() => {
    setLoading(true);
    fetch(`${url}/create_preference`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderData),
    })
      .then((res) => res.json())
      .then((preference) => {
        createPayment(preference.id);
      })
      .catch(function () {
        alert("Unexpected error");
      });
  }, [orderData]);

  useEffect(() => {
    createPreference();
  }, [createPreference]);

  return (
    <Modal
      show={props.show}
      onClick={props.onClick}
      className={classes["checkout-container"]}
    >
      <div className={classes.checkout}>
        <button className={classes.close} onClick={props.onClick}>
          x
        </button>
        <h1>Checkout Payment</h1>
        <div className={classes["form-payment"]}>
          <div className={classes["products"]}>
            <h2>Summary</h2>
            <div className={classes.spacer} />
            <div className={classes["item-list"]}>
              {orderData.map((item) => (
                <div key={item.description} className={classes["item"]}>
                  <p>
                    {item.description} x {item.quantity}
                  </p>
                  <p className={classes.price}>
                    $ {item.price.toFixed(2) * item.quantity}
                  </p>
                </div>
              ))}
            </div>
            <div className={classes.spacer} />
            <div className={classes.total}>
              Total<span> ${cartCtx.totalAmount.toFixed(2)}</span>
            </div>
            {loading && <div className="loader">Loading...</div>}
            <div id="button-checkout" />
          </div>
        </div>
        <p className={classes["go-back"]} onClick={props.goBack}>
          Go Back
        </p>
      </div>
    </Modal>
  );
};

export default MercadoPagoComponent;
