import React from "react";
import { useTranslation } from "react-i18next";
import classes from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={classes.banner}>
        <div className={classes["banner-image"]} />
        <div className={classes["banner-inner"]}>
          <h1>{t("privacy_policy_title")}</h1>
        </div>
        <div className={classes.spacer}></div>
      </div>
      <div className={classes["main-section-container"]}>
        <div className={classes["main-section"]}>
          <h2>{t("privacy_policy_1")}</h2>
          <p>{t("privacy_policy_2")}</p>
          <h3>{t("privacy_policy_3")}</h3>
          <p>{t("privacy_policy_4")}</p>
          <h3>{t("privacy_policy_5")}</h3>
          <p>{t("privacy_policy_6")}</p>
          <h3>{t("privacy_policy_7")}</h3>
          <p>{t("privacy_policy_8")}</p>
          <h3>{t("privacy_policy_9")}</h3>
          <p>{t("privacy_policy_10")}</p>
          <ol>
            <li>{t("privacy_policy_11")}</li>
            <li>{t("privacy_policy_12")}</li>
          </ol>
          <p>{t("privacy_policy_13")}</p>
          <h3>{t("privacy_policy_14")}</h3>
          <p>{t("privacy_policy_15")}</p>
          <h3>{t("privacy_policy_16")}</h3>
          <p>{t("privacy_policy_17")}</p>
          <h3>{t("privacy_policy_18")}</h3>
          <p>{t("privacy_policy_19")}</p>
          <h3>{t("privacy_policy_20")}</h3>
          <p>{t("privacy_policy_21")}</p>
          <h3>{t("privacy_policy_22")}</h3>
          <p>{t("privacy_policy_23")}</p>
          <h3>{t("privacy_policy_24")}</h3>
          <p>{t("privacy_policy_25")}</p>
          <h3>{t("privacy_policy_26")}</h3>
          <p>{t("privacy_policy_27")}</p>
          <ol>
            <li>{t("privacy_policy_28")}</li>
            <li>{t("privacy_policy_29")}</li>
            <li>{t("privacy_policy_30")}</li>
            <li>{t("privacy_policy_31")}</li>
          </ol>
          <p>{t("privacy_policy_32")}</p>
          <h3>{t("privacy_policy_33")}</h3>
          <p>{t("privacy_policy_34")}</p>
          <h3>{t("privacy_policy_35")}</h3>
          <p>{t("privacy_policy_36")}</p>
          <h3>{t("privacy_policy_37")}</h3>
          <p>{t("privacy_policy_38")}</p>
          <h3>{t("privacy_policy_39")}</h3>
          <p>{t("privacy_policy_40")}</p>
          <h3>{t("privacy_policy_41")}</h3>
          <p>{t("privacy_policy_42")}</p>
          <h3>{t("privacy_policy_43")}</h3>
          <p>{t("privacy_policy_44")}</p>
          <h3>{t("privacy_policy_45")}</h3>
          <p>{t("privacy_policy_46")}</p>
          <h3>{t("privacy_policy_47")}</h3>
          <p>{t("privacy_policy_48")}</p>
          <h3>{t("privacy_policy_49")}</h3>
          <p>{t("privacy_policy_50")}</p>
          <h3>{t("privacy_policy_51")}</h3>
          <p>{t("privacy_policy_52")}</p>
          <h3>{t("privacy_policy_53")}</h3>
          <p>{t("privacy_policy_54")}</p>
          <h3>{t("privacy_policy_55")}</h3>
          <p>{t("privacy_policy_56")}</p>
          <h3>{t("privacy_policy_57")}</h3>
          <p>{t("privacy_policy_58")}</p>

          <ol>
            <li>{t("privacy_policy_59")}</li>
            <li>{t("privacy_policy_60")}</li>
            <li>{t("privacy_policy_61")}</li>
            <li>{t("privacy_policy_62")}</li>
            <li>{t("privacy_policy_63")}</li>
            <li>{t("privacy_policy_64")}</li>
          </ol>
          <h3>{t("privacy_policy_65")}</h3>
          <p>{t("privacy_policy_66")}</p>
          <h3>{t("privacy_policy_67")}</h3>
          <p>{t("privacy_policy_68")}</p>
          <h3>{t("privacy_policy_69")}</h3>
          <p>{t("privacy_policy_70")}</p>
          <h3>{t("privacy_policy_71")}</h3>
          <p>{t("privacy_policy_72")}</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
