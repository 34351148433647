import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import classes from "./SideMenu.module.css";
import { useTranslation } from "react-i18next";
import Backdrop from "../UI/Backdrop";
import i18next from "i18next";
import argentinaFlag from "../../assets/img/argentina-flag.png";
import usaFlag from "../../assets/img/usa-flag.png";
import GoogleAnalyticsTracker from "./../../helpers/GoogleAnalyticsTracker";

const SideMenu = (props) => {
  const customClasses = `${classes["side-menu-container"]} ${
    props.show ? classes.show : ""
  }`;
  const { t } = useTranslation();
  const environment = process.env.REACT_APP_GOOGLE_ANALYTICS_ENV + " - ";
  const gaService = GoogleAnalyticsTracker(environment + "Side Menu");

  return (
    <Fragment>
      <Backdrop show={props.show} onClick={props.onCloseSideMenu} />
      <div className={customClasses}>
        <div className={classes["side-menu-content"]}>
          <div className={classes["side-menu-links"]}>
            <NavLink
              onClick={() => {
                gaService.eventTracker("Home");
                props.onCloseSideMenu();
              }}
              to="/home"
              activeClassName={classes.active}
            >
              {t("menu_home")}
            </NavLink>
            <NavLink
              onClick={() => {
                gaService.eventTracker("Blog");
                props.onCloseSideMenu();
              }}
              to="/blog"
              activeClassName={classes.active}
            >
              {t("menu_blog")}
            </NavLink>
            <NavLink
              onClick={() => {
                gaService.eventTracker("Products");
                props.onCloseSideMenu();
              }}
              to="/products"
              activeClassName={classes.active}
            >
              {t("menu_products")}
            </NavLink>
            <NavLink
              onClick={() => {
                gaService.eventTracker("About");
                props.onCloseSideMenu();
              }}
              to="/about"
              activeClassName={classes.active}
            >
              {t("menu_about")}
            </NavLink>
            <NavLink
              onClick={() => {
                gaService.eventTracker("Contact");
                props.onCloseSideMenu();
              }}
              to="/contact"
              activeClassName={classes.active}
            >
              {t("menu_contact")}
            </NavLink>
          </div>
          <div className={classes["language"]}>
            <div
              onClick={() => i18next.changeLanguage("es")}
              style={{
                backgroundImage: `url(${argentinaFlag})`,
              }}
            />
            <div
              onClick={() => i18next.changeLanguage("en")}
              style={{
                backgroundImage: `url(${usaFlag})`,
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SideMenu;
