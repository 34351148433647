import React, { Fragment, useState } from "react";
import FunctionsHelper from "../../../../helpers/FunctionsHelper";
import OrderModal from "./OrderModal";
import { useTranslation } from "react-i18next";
import classes from "./Orders.module.css";

const Orders = (props) => {
  const [openedOrder, setOpenedOrder] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const orders = props.orders;
  const { t } = useTranslation();

  const openModal = (orderId) => {
    setSelectedOrderId(orderId);
    setOpenedOrder(true);
  };

  const closeModal = () => {
    setSelectedOrderId(null);
    setOpenedOrder(false);
  };

  return (
    <Fragment>
      {openedOrder && (
        <OrderModal
          orderId={selectedOrderId}
          openModal={openModal}
          closeModal={closeModal}
        />
      )}
      <div className={classes["main-section-container"]}>
        <div className={classes["main-section"]}>
          <div className={classes["orders-container"]}>
            <h2>{t("profile_orders_title")}</h2>
            {orders && orders.length === 0 && (
              <p className={classes["empty-orders"]}>{t("profile_orders_6")}</p>
            )}
            {orders && orders.length > 0 && (
              <div className={classes["orders-header"]}>
                <div>{t("profile_orders_1")}</div>
                <div>{t("profile_orders_2")}</div>
                <div>{t("profile_orders_3")}</div>
                <div>{t("profile_orders_4")}</div>
                <div>{t("profile_orders_5")}</div>
              </div>
            )}
            {orders &&
              orders.length > 0 &&
              orders.map((order) => {
                const dateSeconds = order.createdDate._seconds;
                var date = new Date(dateSeconds * 1000);

                // console.log(date);
                return (
                  <div className={classes["orders"]} key={order.orderId}>
                    <div className={classes["order-number"]}>
                      #{order.orderId}
                    </div>
                    <div>{FunctionsHelper.formatDate(date)}</div>
                    <h4
                      style={{
                        color: (() => {
                          let color = "black";
                          switch (order.status) {
                            case "complete":
                              color = "#198754";
                              break;
                            case "open":
                              color = "#ffc107";
                              break;
                            case "expired":
                              color = "#dc3545";
                              break;
                            default:
                              break;
                          }
                          return color;
                        })(),
                      }}
                    >
                      {order.status === "complete" &&
                        t("profile_orders_approved")}
                      {(order.status === "open" ||
                        order.status === "pending") &&
                        t("profile_orders_in_process")}
                      {order.status === "expired" &&
                        t("profile_orders_rejected")}
                    </h4>
                    <div>
                      {FunctionsHelper.formatCurrency(order.totalAmount)}
                    </div>
                    <div
                      className={classes["details-button"]}
                      onClick={() => openModal(order.orderId)}
                    >
                      📝
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Orders;
