import React, { useState } from "react";

const CartContext = React.createContext({
  items: [],
  totalQuantity: 0,
  totalAmount: 0,
  addItem: (item) => {},
  removeItem: (item) => {},
  emptyCart: () => {},
});

const quantityCalculator = (items) => {
  return items.reduce((sum, current) => sum + current.quantity, 0);
};

const amountCalculator = (items) => {
  return items.reduce(
    (sum, current) => sum + current.quantity * current.product.price,
    0
  );
};

const retrieveCart = () => {
  const cart = localStorage.getItem("talviCart");
  if (cart) {
    return JSON.parse(cart);
  }
  return null;
};

export const CartContextProvider = (props) => {
  const [items, setItems] = useState([]);
  const [cartQuantity, setCartQuantity] = useState(0);
  const [cartAmount, setCartAmount] = useState(0);
  const [cartRetrieved, setCartRetrieved] = useState(false);

  if (!cartRetrieved) {
    const cart = retrieveCart();
    if (cart) {
      setItems(cart);
      setCartQuantity(quantityCalculator(cart));
      setCartAmount(amountCalculator(cart));
    }
    setCartRetrieved(true);
  }

  const addItemToCart = (selectedItem) => {
    const {
      product,
      selectedInventoryCode,
      selectedSize,
      selectedColor,
      productImage,
    } = selectedItem;
    let newItems;
    let newItem;
    const exists = items.filter(
      (x) =>
        x.product.id === product.id &&
        x.selectedSize === selectedSize &&
        x.selectedColor === selectedColor
    );
    if (exists.length > 0) {
      newItems = items.map((x) => {
        if (
          x.product.id === product.id &&
          x.selectedSize === selectedSize &&
          x.selectedColor === selectedColor
        ) {
          newItem = { ...x };
          newItem.quantity++;
          return newItem;
        } else return x;
      });
    } else {
      newItems = [
        ...items,
        {
          product,
          quantity: 1,
          selectedInventoryCode,
          selectedSize,
          selectedColor,
          productImage,
        },
      ];
    }
    setItems(newItems);
    setCartQuantity((prevQty) => prevQty + 1);
    setCartAmount(amountCalculator(newItems));
    persistInLocalStorage(newItems);
  };

  const removeItemFromCart = (selectedItem) => {
    const { product, selectedSize, selectedColor } = selectedItem;
    let newItems;
    let newItem;
    const exists = items.filter(
      (x) =>
        x.product.id === product.id &&
        x.selectedSize === selectedSize &&
        x.selectedColor === selectedColor
    );
    if (exists.length > 0) {
      if (exists[0].quantity - 1 === 0) {
        newItems = [
          ...items.filter(
            (x) =>
              !(
                x.product.id === product.id &&
                x.selectedSize === selectedSize &&
                x.selectedColor === selectedColor
              )
          ),
        ];
      } else {
        newItems = items.map((x) => {
          if (
            x.product.id === product.id &&
            x.selectedSize === selectedSize &&
            x.selectedColor === selectedColor
          ) {
            newItem = { ...x };
            newItem.quantity--;
            return newItem;
          } else return x;
        });
      }
    }

    setItems(newItems);
    setCartQuantity((prevQty) => {
      if (prevQty > 0) return prevQty - 1;
      else return 0;
    });
    setCartAmount(amountCalculator(newItems));
    persistInLocalStorage(newItems);
  };

  const emptyCart = () => {
    setItems([]);
    setCartQuantity(0);
    setCartAmount(0);
  };

  const persistInLocalStorage = (cart) => {
    localStorage.setItem("talviCart", JSON.stringify(cart));
  };

  const contextValue = {
    items: items,
    totalQuantity: cartQuantity,
    totalAmount: cartAmount,
    addItem: addItemToCart,
    removeItem: removeItemFromCart,
    emptyCart: emptyCart,
  };

  return (
    <CartContext.Provider value={contextValue}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartContext;
