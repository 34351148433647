import React from "react";
import AuthForm from "../../components/Layout/Auth/AuthForm";
import classes from "./Auth.module.css";

const Auth = () => {
  return (
    <div className={classes.auth}>
      <AuthForm />
    </div>
  );
};

export default Auth;
