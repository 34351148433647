import React, { useState, useCallback, useEffect } from "react";
import { Urls } from "../enums/Constants";
import Orders from "../components/Layout/Profile/Order/Orders";
import MyAccount from "../components/Layout/Profile/MyAccount";
import ChangePassword from "../components/Layout/Profile/ChangePassword";
import { useAuth } from "../store/auth-context";
import { database } from "../firebase";
import { useTranslation } from "react-i18next";
import classes from "./Profile.module.css";
import HelmetContent from "../components/Layout/HelmetContent";

const Profile = () => {
  const { currentUser, logout, updatePassword, updateUser } = useAuth();
  const [validOrders, setValidOrders] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [orders, setOrders] = useState([]);
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [page, setPage] = useState(1);
  const pages = { myAccount: 1, orders: 2, changePassword: 3 };
  const { t } = useTranslation();
  const frontEndUrl = process.env.REACT_APP_FRONTEND_URL;
  const helmetData = {
    helmetTitle: "My Profile | Talvi Wear",
    helmetContent:
      "Welcome to Talvi Wear's login page! By logging in to your account, you can enjoy a more personalized shopping experience and easily keep track of your orders and shipping information",
    helmetUrl: frontEndUrl + "profile",
    helmetImage: "/logo192.jpg",
  };

  const getUserData = useCallback(async () => {
    setPage(1);
    if (currentUser) {
      const user = await database.users.doc(currentUser.uid).get();
      if (!user) {
        // console.log("No such document!");
      } else {
        const userData = user.data();
        if (userData) {
          setUserInfo((prevState) => ({
            ...prevState,
            firstName: userData.first_name,
            lastName: userData.last_name,
            email: userData.email,
          }));
          setLoadingUserData(false);
        }
      }
    }
  }, [currentUser]);

  const updateUserHandler = async (userInfo) => {
    try {
      const result = await updateUser(userInfo);

      return result;
    } catch (error) {
      return error;
    }
  };

  const getOrders = useCallback(() => {
    setPage(2);
    const userEmail = currentUser.email;
    if (userEmail) {
      fetch(`${Urls.backEndUrl}/get_orders`, {
        method: "POST",
        body: JSON.stringify({
          email: userEmail,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data) {
            setOrders(data.orders);
            setValidOrders(true);
            setLoadingOrders(false);
          }
        })
        .catch(function () {
          alert("No orders found");
        });
    }
  }, [currentUser.email]);

  const changePasswordHandler = () => {
    setPage(3);
  };

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <div>
      <HelmetContent helmetData={helmetData} />
      <div className={classes.banner}>
        <div className={classes["banner-image"]} />
        <div className={classes["banner-inner"]}>
          <h1>{t("profile_my_account_title")}</h1>
        </div>
        <div className={classes.spacer}></div>
      </div>
      <div className={classes["main-section-container"]}>
        <div className={classes["main-section"]}>
          <div className={classes["profile-menu"]}>
            <p onClick={getUserData}>{t("profile_my_account").toUpperCase()}</p>
            <p onClick={getOrders}>{t("profile_orders_title").toUpperCase()}</p>
            <p onClick={changePasswordHandler}>
              {t("profile_change_password").toUpperCase()}
            </p>
            <p onClick={logout}>{t("profile_logout").toUpperCase()}</p>
          </div>
          <div className={classes["profile-content"]}>
            {pages.myAccount === page && (
              <MyAccount
                updateUser={updateUserHandler}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                loadingUserData={loadingUserData}
              />
            )}
            {pages.orders === page && (
              <div>
                {loadingOrders && <div className="loader">Loading...</div>}
                {validOrders && <Orders orders={orders} />}
                {!validOrders && !loadingOrders && (
                  <p className={classes["no-orders"]}>
                    {t("profile_orders_empty")}
                  </p>
                )}
              </div>
            )}
            {pages.changePassword === page && (
              <ChangePassword updatePassword={updatePassword} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
