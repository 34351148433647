import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import Button from "../../UI/Button";
import ReCAPTCHA from "react-google-recaptcha";
import ValidationHelper from "../../../helpers/ValidationHelper";
import { Urls } from "../../../enums/Constants";
import { useTranslation } from "react-i18next";
import classes from "./ContactForm.module.css";

const captchaKey = "6Ldj74UeAAAAAKCS_h2JXrzmsIhS3o3bdtFiqk_c";

const ContactForm = () => {
  const [validCaptcha, setValidCaptcha] = useState(false);
  const [isSubmittedTouched, setIsSubmittedTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formInfo, setFormInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const captcha = useRef();
  const { t } = useTranslation();

  const validateForm = useCallback(() => {
    const valid =
      ValidationHelper.validateSimpleText(formInfo.firstName) &&
      ValidationHelper.validateSimpleText(formInfo.lastName) &&
      ValidationHelper.validateEmail(formInfo.email) &&
      ValidationHelper.validateSimpleText(formInfo.message) &&
      validCaptcha;
    setIsValid(valid);
  }, [formInfo, validCaptcha]);

  const captchaChangedHandler = (value) => {
    setValidCaptcha(value);
  };

  const sendMessageHandler = (e) => {
    e.preventDefault();
    setIsSubmittedTouched(true);
  };

  const clearForm = () => {
    setFormInfo({
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    });
    captcha.current.reset();
  };

  useEffect(() => {
    validateForm();
  }, [validateForm, isValid, isSubmittedTouched]);

  const sendMessage = useCallback(() => {
    if (isValid && isSubmittedTouched) {
      setLoading(true);
      fetch(`${Urls.backEndUrl}/send_contact_message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: formInfo,
        }),
      })
        .then((res) => res.json())
        .then((message) => {
          setLoading(false);
          alert("Message sent");
        })
        .catch((error) => {
          setLoading(false);
          alert("Unexpected error: " + error);
        })
        .finally(() => {
          setIsSubmittedTouched(false);
          clearForm();
        });
    }
  }, [formInfo, isValid, isSubmittedTouched]);

  useEffect(() => {
    if (isValid) {
      sendMessage();
    }
  }, [isValid, sendMessage]);

  return (
    <Fragment>
      <h2>{t("contact_subtitle")}</h2>
      <form>
        <div className={classes["same-row"]}>
          <div className={classes["middle-column"]}>
            <label htmlFor="firstname">{t("contact_1")}</label>
            <input
              onChange={(e) => {
                setFormInfo((prevState) => ({
                  ...prevState,
                  firstName: e.target.value,
                }));
                validateForm();
              }}
              value={formInfo.firstName}
              name="firstname"
              type="text"
            />
            {isSubmittedTouched &&
              !ValidationHelper.validateSimpleText(formInfo.firstName) && (
                <p className={classes.warning}>
                  {t("form_please_complete_field")}
                </p>
              )}
          </div>
          <div className={classes["input"]}>
            <label htmlFor="lastname">{t("contact_2")}</label>
            <input
              onChange={(e) => {
                setFormInfo((prevState) => ({
                  ...prevState,
                  lastName: e.target.value,
                }));
                validateForm();
              }}
              value={formInfo.lastName}
              name="lastname"
              type="text"
            />
            {isSubmittedTouched &&
              !ValidationHelper.validateSimpleText(formInfo.lastName) && (
                <p className={classes.warning}>
                  {t("form_please_complete_field")}
                </p>
              )}
          </div>
        </div>
        <div className={classes["input"]}>
          <label htmlFor="email">{t("contact_3")}</label>
          <input
            onChange={(e) => {
              setFormInfo((prevState) => ({
                ...prevState,
                email: e.target.value,
              }));
              validateForm();
            }}
            value={formInfo.email}
            name="email"
            type="email"
          />
          {isSubmittedTouched &&
            !ValidationHelper.validateEmail(formInfo.email) && (
              <p className={classes.warning}>
                {t("form_please_complete_field")}
              </p>
            )}
        </div>
        <div className={classes["input"]}>
          <label htmlFor="message">{t("contact_4")}</label>
          <textarea
            onChange={(e) => {
              setFormInfo((prevState) => ({
                ...prevState,
                message: e.target.value,
              }));
              validateForm();
            }}
            value={formInfo.message}
            placeholder="Message..."
            name="message"
            type="text"
          />
          {isSubmittedTouched &&
            !ValidationHelper.validateSimpleText(formInfo.message) && (
              <p className={classes.warning}>
                {t("form_please_complete_field")}
              </p>
            )}
        </div>
        <div className={classes["captcha"]}>
          <ReCAPTCHA
            ref={captcha}
            sitekey={captchaKey}
            onChange={() => captchaChangedHandler(true)}
            onExpired={() => captchaChangedHandler(false)}
          />
        </div>

        <Button type="submit" onClick={sendMessageHandler} disabled={!isValid}>
          {t("contact_submit")}
          {loading && <div className="loader">Loading...</div>}
        </Button>
      </form>
    </Fragment>
  );
};

export default ContactForm;
