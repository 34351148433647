import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Auth from "../../pages/Auth/Auth";
import ResetPassword from "../../pages/Auth/ResetPassword";
import Profile from "../../pages/Profile";
import Home from "../../pages/Home";
import Blog from "../../pages/Blog";
import Products from "../../pages/Products";
import About from "../../pages/About";
import Contact from "../../pages/Contact";
import Checkout from "../../pages/Checkout";
import Success from "../../pages/Success";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import classes from "./Content.module.css";
import { useAuth } from "../../store/auth-context";

const Content = (props) => {
  const { currentUser } = useAuth();
  const isLoggedIn = currentUser ? true : false;

  useEffect(() => {
    props.reactGoogleAnalytics(
      window.location.pathname,
      window.location.search
    );
    props.reactFacebook(window.location.pathname, window.location.search);
  }, [props]);

  return (
    <div className={classes.Content}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/auth">
          {!isLoggedIn ? <Auth /> : <Redirect to="/profile" />}
        </Route>
        <Route path="/reset_password">
          {!isLoggedIn ? <ResetPassword /> : <Redirect to="/profile" />}
        </Route>
        <Route path="/profile">
          {isLoggedIn ? <Profile /> : <Redirect to="/auth" />}
        </Route>
        <Route path="/home" component={Home} />
        <Route path="/products" component={Products} />
        <Route path="/blog" component={Blog} />
        <Route path="/blog/:postId" component={Blog} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/success" component={Success} />
        <Route path="/pending">
          <Redirect to="/success" />
        </Route>
        <Route path="/failed">
          <Redirect to="/success" />
        </Route>
        <Route path="/policy" component={PrivacyPolicy} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
        <Route path="/checkout_iframe.html" />
      </Switch>
    </div>
  );
};

export default Content;
