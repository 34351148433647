import React from "react";
import classes from "./Color.module.css";

const Color = (props) => {
  const selectedClass = classes["selected-color"];
  const colors = props.colors.map((x) => x.color);

  const onColorSelect = (selectedColor) => {
    props.onClick(selectedColor);
  };

  return (
    <div className={classes["color-container"]}>
      {colors.map((color) => (
        <button
          key={color.colorName}
          className={
            props.selectedColor === color.colorName ? selectedClass : ""
          }
          onClick={() => onColorSelect(color.colorName)}
          style={{ backgroundColor: color.colorCode }}
        />
      ))}
    </div>
  );
};

export default Color;
